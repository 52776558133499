import React, { useState, useMemo, useEffect, useCallback } from 'react';

import { translator } from '~/presentation/components/i18n';
import {
  Container,
  TitleDoc,
  Item,
  ItemTitle,
} from './styles/StyledSelectDocumentType';

import { MedicalCertificateSideBar } from '../medicalCertificateSiderBar';
import { ExamRequestSideBar } from '../examRequestSideBar';
import SimplePrescription from '../simplePrescription/SimplePrescription';
import { Pages } from '../clinicalDocs';
import { makeRemoteGetAppointmentById } from '~/main/factories/usecases/appointment/GetAppointmentbyIdFactory';
import { GetAppointmentById } from '~/domain/usecases/appointment/remote';

enum DocumentType {
  NONE = 0,
  ATESTADO_MEDICO = 1,
  RECEITA_SIMPLES = 2,
  RECEITA_ESPECIAL = 3,
  RECEITA_ANTIMICROBIANO = 4,
  REQUISIÇÃO_DE_EXAMES = 5,
  RELATORIO_MEDICO = 6,
}

interface SelectDocumentTypeProps {
  pageSelected: Pages;
  setPageSelected: (pageSelected: Pages) => void;
  goBackPressed: boolean;
  goBackToListDocument: () => void;
  appointment?: GetAppointmentById.Model;
}

const SelectDocumentType: React.FC<SelectDocumentTypeProps> = ({
  goBackPressed,
  goBackToListDocument,
  pageSelected,
  setPageSelected,
  appointment,
}) => {
  const [selected, setSelected] = useState<DocumentType>(DocumentType.NONE);

  const Options = [
    {
      id: DocumentType.ATESTADO_MEDICO,
      name: translator('Atestado Médico'),
      disabled: false,
    },
    {
      id: DocumentType.RECEITA_SIMPLES,
      name: translator('Receita Simples'),
      disabled: false,
    },
    {
      id: DocumentType.RECEITA_ESPECIAL,
      name: translator('Receita Especial'),
      disabled: true,
    },
    {
      id: DocumentType.RECEITA_ANTIMICROBIANO,
      name: translator('Receita Antimicrobiano'),
      disabled: true,
    },
    {
      id: DocumentType.REQUISIÇÃO_DE_EXAMES,
      name: translator('Requisição de Exames'),
      disabled: false,
    },
    {
      id: DocumentType.RELATORIO_MEDICO,
      name: translator('Relatório Médico'),
      disabled: true,
    },
  ];

  const handleClick = (type: DocumentType) => {
    if (
      type !== DocumentType.RELATORIO_MEDICO &&
      type !== DocumentType.RECEITA_ANTIMICROBIANO &&
      type !== DocumentType.RECEITA_ESPECIAL
    ) {
      setPageSelected(Pages.CREATING_DOCUMENT);
      setSelected(type);
    }
  };

  const handlePage = useCallback(
    (page: Pages) => {
      switch (page) {
        case Pages.LIST_DOCUMENT:
        case Pages.SELECT_DOCUMENT_TYPE:
          setPageSelected(Pages.LIST_DOCUMENT);
          goBackToListDocument();
          break;
        case Pages.CREATING_DOCUMENT:
          setPageSelected(Pages.SELECT_DOCUMENT_TYPE);
          break;
        default:
          break;
      }
    },
    [goBackToListDocument, setPageSelected],
  );

  const renderOptions = useMemo(() => {
    switch (selected) {
      case DocumentType.ATESTADO_MEDICO:
        return (
          <MedicalCertificateSideBar
            goBackToListDocument={goBackToListDocument}
            appointment={appointment}
          />
        );
      case DocumentType.RECEITA_SIMPLES:
        return (
          <SimplePrescription
            goBackToListDocument={goBackToListDocument}
            appointment={appointment}
          />
        );
      case DocumentType.REQUISIÇÃO_DE_EXAMES:
        return (
          <ExamRequestSideBar
            goBackToListDocument={goBackToListDocument}
            appointment={appointment}
          />
        );
      default:
        break;
    }

    return null;
  }, [goBackToListDocument, selected, appointment]);

  useEffect(() => {
    if (goBackPressed) {
      setSelected(DocumentType.NONE);
      handlePage(pageSelected);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [goBackPressed]);

  return (
    <>
      {selected === DocumentType.NONE ? (
        <Container>
          <TitleDoc>
            {`${translator('Modelos de documentos clínicos')}`}
          </TitleDoc>
          {Options.map(item => (
            <Item disabled={item.disabled} onClick={() => handleClick(item.id)}>
              <ItemTitle>{item.name}</ItemTitle>
            </Item>
          ))}
        </Container>
      ) : (
        renderOptions
      )}
    </>
  );
};

export default SelectDocumentType;
