import { zodResolver } from '@hookform/resolvers/zod';
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router';
import ReactInputMask from 'react-input-mask';
import { iProfessional } from '~/domain/interfaces/models';
import { translator } from '~/presentation/components/i18n';
import InputKeyboardDate from '~/presentation/components/inputKeyboardDate';
import { Button, Input, Select } from '~/presentation/components/UI';
import { schemaPacientData } from '~/validation/validators/document/MedicalCertificateSideBarValidator';
import { iCreateMedicalCertificate, iListConsultant } from '../interface';
import {
  Container,
  Content,
  Form,
  Navigation,
} from '../styles/StyledGeneralData';
import SearchSelect from '~/presentation/components/UI/searchSelect';
import _ from 'lodash';
import { GetAppointmentById } from '~/domain/usecases/appointment/remote';

export interface ownProps {
  consultant: iListConsultant[];
  next: (data: iCreateMedicalCertificate) => any;
  professional?: iProfessional;
  state?: iCreateMedicalCertificate;
  back?: () => void;
  appointment?: GetAppointmentById.Model;
}

interface iStateParams {
  appointmentId: string;
  professionalId: number;
  consultantId: number;
}
// TODO CHANGE COMPONENT NAME
const PacientData: React.FC<ownProps> = ({
  next,
  back,
  consultant,
  state,
  appointment,
}): JSX.Element => {
  const stateParams = useLocation<iStateParams>().state;

  const [consultantId, setConId] = useState<number | undefined>(
    stateParams.consultantId,
  );

  const { errors, handleSubmit, register, setValue, watch } = useForm({
    mode: 'onChange',
    shouldFocusError: true,
    resolver: zodResolver(schemaPacientData),
    defaultValues: {
      situation:
        appointment?.appointment.service === 'INSTANTANEOUS'
          ? 'NOT-REGISTERED'
          : 'REGISTERED',
      patient: {
        id: state?.patient?.id || -1,
      },
      ...state,
    },
  });

  const [thisSituation, setThisSituation] = useState<string | undefined>(
    watch('situation') ?? 'REGISTERED',
  );

  const disableOptions = appointment?.appointment.service === 'INSTANTANEOUS';

  const onSubmit = handleSubmit(data => {
    if (data.birthDate) {
      const age = calculateAge(data.birthDate);
      next({ ...data, patient: { ...data.patient, age: Number(age) } });
    } else {
      next({ ...data });
    }
  });

  function calculateAge(birthdate: string): number | null {
    const today = new Date();
    const birthdateParts = birthdate.split('/');

    // Ensure the birthdate is in the correct format
    if (birthdateParts.length !== 3) {
      return null;
    }

    // Extract day, month, and year from the birthdate
    const birthDay = parseInt(birthdateParts[0], 10);
    const birthMonth = parseInt(birthdateParts[1], 10);
    const birthYear = parseInt(birthdateParts[2], 10);

    // Calculate the age
    let age = today.getFullYear() - birthYear;

    // Check if the birthday hasn't occurred yet this year
    const todayMonth = today.getMonth() + 1;
    const todayDay = today.getDate();

    if (
      todayMonth < birthMonth ||
      (todayMonth === birthMonth && todayDay < birthDay)
    ) {
      age -= 1;
    }

    return age;
  }

  const onChangeDate = (value: string) => {
    const date = value.trim();

    setValue('birthDate', date);
  };

  const generateConsultantSelectOptions = useCallback(() => {
    return _.sortBy(
      consultant?.flatMap(value => {
        return [
          {
            value: value.id,
            label: `${value.firstName} ${value.lastName}`,
          },
        ];
      }),
      item => item?.label.replace(/[^a-zA-Z0-9]/g, '').toLocaleLowerCase(),
    );
  }, [consultant]);

  const consultantMultiselectSelected = useMemo(() => {
    return generateConsultantSelectOptions().find(
      option => option.value === watch('patient.id'),
    );
  }, [generateConsultantSelectOptions, watch]);

  useEffect(() => {
    register('patient.id');

    if (stateParams.consultantId) {
      setConId(stateParams.consultantId);
      setValue('patient.id', stateParams.consultantId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateParams]);

  useEffect(() => {
    setValue('situation', thisSituation);
    if (thisSituation === 'NOT-REGISTERED') {
      setValue('patient.id', -1);
    }
    if (thisSituation === 'REGISTERED') {
      setValue('patient.fullname', undefined);
      setValue('birthDate', undefined);
    }
  }, [thisSituation]);

  useEffect(() => {
    if (appointment?.appointment.service === 'INSTANTANEOUS') {
      register('situation');
      register('birthDate');
      register('patient.fullname');
      register('patient.gender');

      const rawBirthDate =
        appointment.appointment.info?.birthDate ??
        appointment.appointment.info?.birthdate ??
        undefined;
      const formattedBirthDate = rawBirthDate
        ? rawBirthDate.split('-').reverse().join('/')
        : undefined;

      setValue('situation', 'NOT-REGISTERED');
      setValue('birthDate', formattedBirthDate ?? state?.birthDate);
      setValue(
        'patient.fullname',
        appointment.appointment.info.name ??
          appointment.appointment.info.fullname,
      );
      setValue(
        'patient.gender',
        appointment.appointment.info.sex === 'M' ? 'MALE' : 'FEMALE',
      );
      setThisSituation('NOT-REGISTERED');
    }
  }, [appointment]);

  return (
    <Container>
      <Content>
        <Form onSubmit={onSubmit}>
          <Select
            id="select_situation"
            label={`${translator('Situação cadastral')}`}
            onChange={e => {
              setThisSituation(e.target.value);
              setValue('situation', e.target.value);
            }}
            name="situation"
            defaultValue={watch('situation')}
            register={() => register('situation')}
            error={Boolean(errors.situation)}
            message={errors?.situation?.message}
            value={watch('situation')}
            disabled={disableOptions}
          >
            <option value="REGISTERED">
              {translator('Paciente cadastrado')}
            </option>
            <option value="NOT-REGISTERED">
              {translator('Paciente não cadastrado')}
            </option>
          </Select>

          {thisSituation === 'REGISTERED' ? (
            <SearchSelect
              key={consultantId}
              id="select_patient"
              name="patient.id"
              register={() => register('patient.id')}
              label={`${translator('Paciente')}`}
              placeholder={translator('Selecione um paciente')}
              onChange={e => {
                setConId(Number(e?.value));
                setValue('patient.id', Number(e?.value));
              }}
              error={Boolean(errors?.patient?.id)}
              message={
                errors?.patient?.id?.message
                  ? translator(errors?.patient?.id?.message)
                  : ''
              }
              options={generateConsultantSelectOptions()}
              value={consultantMultiselectSelected}
            />
          ) : (
            <Input
              id="input_patient"
              label={`${translator('Paciente')}`}
              onChange={e => setValue('patient.fullname', e.target.value)}
              name="patient.fullname"
              defaultValue={watch('patient.fullname')}
              register={() => register('patient.fullname')}
              error={Boolean(errors?.patient?.fullname)}
              message={
                errors?.patient?.fullname?.message
                  ? translator(errors?.patient?.fullname?.message)
                  : ''
              }
              placeholder="Digite o nome do paciente"
              autoFocus
              disabled={disableOptions}
            />
          )}
          {thisSituation === 'NOT-REGISTERED' && (
            <>
              <div>
                <ReactInputMask
                  mask="99/99/9999"
                  name="birthDate"
                  className="birthdate"
                  defaultValue={watch('birthDate')}
                  ref={() => register('birthDate')}
                  onChange={e => {
                    onChangeDate(e.target.value.trim());
                  }}
                  value={watch('birthDate')}
                  disabled={
                    disableOptions &&
                    !!(
                      appointment.appointment.info?.birthDate ||
                      appointment.appointment.info?.birthdate
                    )
                  }
                >
                  <Input
                    gridArea="birthDate"
                    id="registerBirthDate"
                    placeholder={translator('DD/MM/AAAA')}
                    label={translator('Data de Nascimento')}
                    error={Boolean(errors.birthDate)}
                    message={
                      errors?.birthDate?.message
                        ? translator(errors?.birthDate?.message)
                        : ''
                    }
                    required
                    disabled={
                      disableOptions &&
                      !!(
                        appointment.appointment.info?.birthDate ||
                        appointment.appointment.info?.birthdate
                      )
                    }
                  />
                </ReactInputMask>
              </div>
              <Select
                id="select_gender"
                label={`${translator('Sexo')}`}
                defaultValue={state?.patient?.gender}
                onChange={e => setValue('patient.gender', e.target.value)}
                name="patient.gender"
                register={() => register('patient.gender')}
                value={watch('patient.gender')}
                error={Boolean(errors.patient?.gender)}
                message={errors?.patient?.gender?.message}
                disabled={disableOptions}
              >
                <option value="selectSexo">
                  {translator('Selecione o sexo')}
                </option>
                <option value="MALE">{translator('Masculino')}</option>
                <option value="FEMALE">{translator('Feminino')}</option>
              </Select>
            </>
          )}
        </Form>
        <Navigation>
          <Button variant="secundary" onClick={back} size="small" rounded>
            Anterior
          </Button>
          <Button variant="primary" onClick={onSubmit} size="small" rounded>
            Próximo
          </Button>
        </Navigation>
      </Content>
    </Container>
  );
};

export default PacientData;
