import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { DownloadZipFilesDiagnosisReport as UsecaseRemoteDownloadZipFilesDiagnosisReport } from '~/domain/usecases/diagnosisReport/remote';

import { RemoteDownloadZipFilesDiagnosisReport } from '~/data/repository/diagnosisReport';

/**
 * send request via API.
 */

export const makeRemoteDownloadZipFilesDiagnosisReport =
  (): UsecaseRemoteDownloadZipFilesDiagnosisReport =>
    new RemoteDownloadZipFilesDiagnosisReport(
      makeApiUrl('/reports'),
      makeHttpClient(),
    );
