import axios, { AxiosResponse } from 'axios';
import { createIntl, createIntlCache } from 'react-intl';
import storeDev from '~/data/store';
import { ReduxLogout } from '~/data/store/reducer/auth/usecases/ReduxLogout';
import { iStore } from '~/domain/interfaces/models';
import translations from '~/infra/i18n/locales';
import { AlertMessage } from '~/presentation/components/messages/AlertMessage';
import { getLocale } from '~/utils/getLocale';
import {
  HttpClient,
  HttpRequest,
  HttpResponse,
} from '../../../data/protocols/http';

const cache = createIntlCache();

const intl = createIntl(
  {
    locale: String(getLocale()),
    messages: translations[getLocale()],
  },
  cache,
);

class AxiosHttpClient implements HttpClient<any> {
  /**
   * Request for external API.
   * @param data data to make the request.
   */
  public request = async (data: HttpRequest): Promise<HttpResponse> => {
    let axiosResponse: AxiosResponse;
    const store: iStore = storeDev.getState();

    const { token } = store.auth.access;

    let authorizationHeader = data.headers;

    if (
      token &&
      (!data.headers ||
        (typeof data.headers === 'object' && Object.keys(data.headers).length))
    ) {
      authorizationHeader = {
        Authorization: `Bearer ${token}`,
        ...data.headers,
      };
    }

    try {
      axiosResponse = await axios.request({
        url: data.url,
        method: data.method,
        data: data.body,
        headers: {
          ...authorizationHeader,
          'X-Frame-Options': 'SAMEORIGIN',
          'X-Content-Type-Options': 'nosniff',
          'Referrer-Policy': 'no-referrer',
          'Permissions-Policy': 'interest-cohort=()',
          'Cross-Origin-Embedder-Policy': 'require-corp',
          'Cross-Origin-Opener-Policy': 'same-origin',
          'Cross-Origin-Resource-Policy': 'same-origin',
          'Access-Control-Expose-Headers': '*',
        },
        responseType: data.responseType,
        params: data.params,
      });
    } catch (error: any) {
      if (error.response?.status === 502 || error.response?.status === 503) {
        let found = false;

        document.querySelectorAll('.notification__message').forEach(node => {
          if (
            node.innerHTML.includes('Falha interna no servidor') ||
            node.innerHTML.includes('Internal server failure') ||
            node.innerHTML.includes('Fallo interno del servidor')
          ) {
            found = true;
          }
        });

        if (!found) {
          AlertMessage({
            message: intl.formatMessage({
              id: 'Falha interna no servidor, tente novamente em minutos.',
            }),
            type: 'danger',
          });
        }
      }

      if (
        error.response?.status === 401 &&
        error.response?.data?.errors?.[0]?.code?.includes('AUTH') &&
        store.auth.info.user?.id &&
        store.auth.access.token
      ) {
        const logoutApp = new ReduxLogout();
        logoutApp.logout();
        window.location.pathname = '/';
      }

      // if (error.response?.status === 403) {
      //   AlertMessage({
      //     message: intl.formatMessage({
      //       id: 'Você não tem permissão para acessar este recurso.',
      //     }),
      //     type: 'info',
      //   });
      //   // redirect to previous page after 3 seconds
      //   setTimeout(() => {
      //     window.history.back();
      //   }, 1000);
      // }

      axiosResponse = error.response;
    }

    return {
      statusCode: axiosResponse?.status,
      body: axiosResponse?.data,
      headers: axiosResponse?.headers,
    };
  };
}

export default AxiosHttpClient;
