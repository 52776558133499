/* eslint-disable no-case-declarations */
/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { TableSortLabel } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import * as _ from 'lodash';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models';
import { makeRemoteGetClinicalDocsByDownload } from '~/main/factories/usecases/clinicalDocs/GetClinicalDocsByDownloadFactory';
import { History } from '~/main/routes';
import { IconSort } from '~/presentation/base/icons';
import { translator } from '~/presentation/components/i18n';
import { SendMail } from '~/presentation/components/messages/email/SendMail';
import ListSkeleton from '~/presentation/components/skeleton/ListSkeleton';
import orderData from '~/utils/orderData';
import ActionsList from '../../actionsList/ActionsList';
import { AvatarBadger } from '../../avatar-roles';
import { DocumentStatus } from '../../statusList';
import { iListClinicalDocument } from '../interface';
import { Avatar } from '../styles/StyledUserItem';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  container: {
    maxHeight: '100vh',
    overflowY: 'auto',
  },
});

interface iTranslate {
  [key: string]: string;
}

interface ownProps {
  dataList: iListClinicalDocument[];
}

type iOrder =
  | 'date'
  | 'fullName'
  | 'statusSignature'
  | 'specialty'
  | 'origin'
  | 'typeIs';
const ClinicalDocuments: React.FC<ownProps> = props => {
  /**
   * @order pameter to sort 'asc' or 'desc'
   */
  const [order, setOrder] = useState<boolean>(true);
  const [categoryToOrder, setCategoryToOrder] = useState(true);
  const [dataList, setData] = useState<iListClinicalDocument[]>(props.dataList);
  const { loading } = useSelector((store: iStore) => store.clinicalDocs);

  const nameOrigin: iTranslate = {
    APPOINTMENT: 'Atendimento',
    DETACHED: 'Avulso',
  };

  const nameTypeIs: iTranslate = {
    BASIC: 'Receita Simples',
    MEDICALCERTIFICATE: 'Atestado Médico',
    EXAMREQUEST: 'Requisição de exame',
    APAC_REPORT: 'Solicitação/Autorização de Procedimento Ambulatorial',
    OPINION: 'Parecer Clínico',
  };

  const arrToCompare = [
    'date',
    'fullName',
    'statusSignature',
    'specialty',
    'origin',
    'typeIs',
  ];
  const [sendingEmail, setSendingEmail] = useState<{
    visible: boolean;
    docId: number;
  }>({ visible: false, docId: 0 });
  const classes = useStyles();
  const columns = [
    {
      id: 'date',
      label: translator('Data'),
      minWidth: 80,
      align: 'left',
    },
    {
      id: 'fullName',
      label: translator('Profissional da saúde'),
      minWidth: 190,
      maxWidth: 300,
      align: 'left',
    },
    {
      id: 'statusSignature',
      label: translator('Assinatura'),
      minWidth: 50,
      align: 'left',
    },
    {
      id: 'specialty',
      label: translator('Especialidade'),
      minWidth: 50,
      align: 'left',
    },

    {
      id: 'origin',
      label: translator('Origem'),
      minWidth: 50,
      align: 'left',
    },
    {
      id: 'typeIs',
      label: translator('Tipo'),
      minWidth: 50,
      align: 'left',
    },
    {
      id: 'actions',
      label: translator('Ações'),
      minWidth: 50,
      align: 'left',
    },
  ];

  const downloadFile = async (id: any) => {
    console.log('Valor do id: ', id);
    if (id === undefined) {
      alert('Selecione um arquivo');
      return;
    }

    if (Number.isNaN(id)) {
      alert('Selecione um arquivo');
      return;
    }

    const data = await makeRemoteGetClinicalDocsByDownload().getByDownload({
      docId: parseInt(`${id}`, 10),
    });

    const link = document.createElement('a');
    const urlToDownload = window.URL.createObjectURL(data as Blob);
    const name = 'receituario';
    link.href = urlToDownload;
    link.download = name;
    link.click();
  };

  const RenderComponents: React.FC<{ id: string; item: any }> = ({
    id,
    item,
  }) => {
    switch (id) {
      case 'actions':
        return (
          <>
            {item.statusSignature === 'SIGNED' ? (
              <ActionsList
                actions={{
                  download: () => downloadFile(item.id),
                  shareEmail: () =>
                    setSendingEmail({ visible: true, docId: item.id }),
                  details: () => {
                    const url = window.location.pathname;

                    History.getHistory().push('/clinicaldoc/detail', {
                      docId: item.id,
                      from: url.replaceAll('/', ''),
                    });
                  },
                }}
              />
            ) : (
              <ActionsList
                actions={{
                  details: () => {
                    const url = window.location.pathname;

                    History.getHistory().push('/clinicaldoc/detail', {
                      docId: item.id,
                      from: url.replaceAll('/', ''),
                    });
                  },
                }}
              />
            )}
          </>
        );
      case 'statusSignature':
        return <DocumentStatus status={item?.statusSignature} />;
      case 'fullName':
        return (
          <Avatar>
            <AvatarBadger
              src={item?.avatar}
              name={item?.fullName}
              size="root"
              status={false}
            />
            <text>{`${item?.fullName}`}</text>
          </Avatar>
        );
      case 'origin':
        const translation = nameOrigin[item?.origin]
          ? translator(nameOrigin[item?.origin])
          : '-';

        return <div>{translation}</div>;
      case 'clinicalDocument':
      case 'typeIs':
        const key = item?.typeIs ?? item?.clinicalDocument;
        const translationTypeIs = nameTypeIs[key]
          ? translator(nameTypeIs[key])
          : '-';

        return <div>{translationTypeIs}</div>;
      default:
        return <div>{item[`${id}`]}</div>;
    }
  };

  const sortByDate = (
    arr: iListClinicalDocument[],
    direction: 'asc' | 'desc',
  ) => {
    return _.orderBy(
      arr,
      item => {
        const splitDate = item.date.split('/');
        const internationalDate = splitDate.reverse().join('-');
        return internationalDate ? new Date(internationalDate).getTime() : 0;
      },
      direction,
    );
  };

  function handleSortByCategory(type: iOrder) {
    switch (type) {
      case 'date':
        const orderStatus = categoryToOrder ? 'asc' : 'desc';

        setData([
          ..._.orderBy(
            dataList,
            dateSort => {
              const splitDate = dateSort.date.split('/');
              const internationalDate = splitDate.reverse().join('-');
              return internationalDate
                ? new Date(internationalDate).getTime()
                : 0;
            },
            `${orderStatus}`,
          ),
        ]);
        return setCategoryToOrder(!categoryToOrder);

      default:
        setOrder(prevState => !prevState);
        return setData([...orderData(dataList, order, type as iOrder)]);
    }
  }

  useEffect(() => {
    setOrder(false);
    setData([...sortByDate(props.dataList, 'desc')]);
  }, [props.dataList, loading]);

  return (
    <TableContainer component={Paper} className={classes.container}>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            {columns.map(item => {
              return (
                <TableCell align="left">
                  {arrToCompare.includes(item.id) ? (
                    <TableSortLabel
                      active
                      IconComponent={() => (
                        <div style={{ paddingLeft: 3 }}>
                          <IconSort id={`sort_${item.id}`} />
                        </div>
                      )}
                      style={{ color: '#8A8A8A', fontWeight: 'normal' }}
                      onClick={() => handleSortByCategory(item.id as iOrder)}
                    >
                      {item.label}
                    </TableSortLabel>
                  ) : (
                    <div style={{ color: '#8A8A8A', fontWeight: 'normal' }}>
                      {item.label}
                    </div>
                  )}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {loading ? (
            <>
              {columns.map(item => {
                return (
                  <TableRow>
                    <ListSkeleton columns={columns} />
                  </TableRow>
                );
              })}
            </>
          ) : (
            dataList.map((item: any) => (
              <TableRow key={item.id}>
                {columns.map((columnProps: any) => {
                  return (
                    <TableCell
                      align={columnProps.align}
                      style={{
                        maxWidth: columnProps.maxWidth,
                        minWidth: columnProps.minWidth,
                      }}
                    >
                      <RenderComponents id={columnProps.id} item={item} />
                      {/* <ListSkeleton columns={columns} /> */}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
      {sendingEmail.visible && (
        <SendMail
          docId={sendingEmail.docId}
          close={() => setSendingEmail({ visible: false, docId: 0 })}
        />
      )}
    </TableContainer>
  );
};

export default ClinicalDocuments;
