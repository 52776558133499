import React, { useState } from 'react';
import { useLocation } from 'react-router';
import { iGuestData } from '~/domain/interfaces/models/GuestData';
import { makeReduxSelectOne } from '~/main/factories/usecases/clinicalDocs/SelectOneFactory';
import { makeRemoteDownloadByCodeSupportDocs } from '~/main/factories/usecases/supportDoc/DownloadByCodeSupportDocsFactory';
import { makeRemoteGetByDownloadSupportDocs } from '~/main/factories/usecases/supportDoc/GetByDownloadSupportDocsFactory';
import {
  IconCrossBlue,
  IconCrossRed,
  IconFiles,
  IconMp4,
  IconNotSignedDocument,
  IconPng,
  IconSignedDocuments,
  Pdf,
  FileEarmarkIcon,
  FileEarmarkTextIcon,
  GroupIcon,
} from '~/presentation/base/icons';
import { translator } from '../i18n';
import { SendMail } from '../messages/email/SendMail';
import { OptionsClinicalDoc } from '../optionsMore';
import OptionsSupportDoc from '../optionsMore/OptionsSupportDoc';
import { More } from '../UI/popover';
import {
  Body,
  Container,
  Prefix,
  SubTitle,
  Suffix,
  Title,
  Wrapper,
} from './styles/StyledDocumentItem';

interface DocumentsItemProps {
  type?: string;
  documentSize?: number | string;
  title: string;
  subTitle: string;
  isSigned?: boolean;
  date: string;
  id?: any;
  open?: Function;
}

const DocumentsItem: React.FC<DocumentsItemProps> = ({
  type = 'clinical-documents',
  title,
  subTitle,
  isSigned,
  date,
  documentSize,
  id,
  open,
}) => {
  const extension = title.split('.').pop();

  const [sendingEmail, setSendingEmail] = useState(false);

  const params = useLocation<{ guestData: iGuestData }>().state;

  function selectIcon() {
    if (extension === 'pdf') {
      return <Pdf />;
    }
    if (extension === 'mp4') {
      return <IconMp4 />;
    }
    if (
      extension === 'png' ||
      extension === 'jpeg' ||
      extension === 'jpg' ||
      extension === 'bmp'
    ) {
      return <IconPng />;
    }
    if (extension === 'odt' || extension === 'doc' || extension === 'docx') {
      return <FileEarmarkTextIcon />;
    }
    if (extension === 'ods' || extension === 'xls' || extension === 'xlsx') {
      return <FileEarmarkIcon />;
    }
    if (extension === 'odp' || extension === 'ppt' || extension === 'pptx') {
      return <GroupIcon />;
    }

    return <IconFiles />;
  }

  /* TODO translator */

  function prefixList() {
    console.log('date: ', date);
    switch (type) {
      case 'clinical-documents':
        return (
          <Wrapper isSigned={isSigned!} id={id}>
            {isSigned ? <IconCrossBlue /> : <IconCrossRed />}
            <text>{date}</text>
          </Wrapper>
        );
      case 'supporting-documents':
        return (
          <Wrapper isSigned={isSigned!} id={id}>
            {selectIcon()}
            <text>{date}</text>
          </Wrapper>
        );
      default:
        return <text>carregando...</text>;
    }
  }
  function suffixList() {
    switch (type) {
      case 'clinical-documents':
        return (
          <Wrapper isSigned={isSigned!} id={`status_${id}`}>
            {isSigned ? (
              <div>
                <IconSignedDocuments />
                <text>{translator('Assinado')}</text>
              </div>
            ) : (
              <div>
                <IconNotSignedDocument />
                <text>{translator('Pendente')}</text>
              </div>
            )}
          </Wrapper>
        );
      case 'supporting-documents':
        return (
          <Wrapper isSigned>
            <span>
              <text>{`${translator('Tamanho')}:`}</text>
              <text>
                <b>{`${documentSize}MB`}</b>
              </text>
            </span>
          </Wrapper>
        );
      default:
        return <text>carregando...</text>;
    }
  }
  function handleDoc(idDoc: number) {
    makeReduxSelectOne().select({
      id: idDoc,
    });
  }

  const handleViewDetails = async () => {
    let downloadUrl = '';
    const code = params.guestData?.guest?.identification?.code;

    if (!code) {
      const { url } = await makeRemoteGetByDownloadSupportDocs().get({
        docId: Number(id),
      });
      downloadUrl = url;
    } else {
      const { url } = await makeRemoteDownloadByCodeSupportDocs().get({
        code: String(code),
        docId: Number(id),
      });
      downloadUrl = url;
    }

    window.open(downloadUrl, '_blank');
  };

  return (
    <>
      <Container id={id}>
        <Prefix>{prefixList()}</Prefix>
        <Body>
          {type === 'clinical-documents' ? (
            <Title id={id} isSigned={isSigned!} onClick={() => handleDoc(id)}>
              {title}
            </Title>
          ) : (
            <Title
              id={id}
              isSigned={isSigned!}
              onClick={() => handleViewDetails()}
            >
              {title}
            </Title>
          )}
          <SubTitle>{subTitle}</SubTitle>
        </Body>
        <Suffix>
          {suffixList()}
          <More
            content={
              type === 'clinical-documents' ? (
                <OptionsClinicalDoc
                  id={id}
                  status={isSigned}
                  openResendEmail={() => setSendingEmail(true)}
                />
              ) : (
                <OptionsSupportDoc
                  supportDocID={Number(id)}
                  extension={extension}
                />
              )
            }
          />
        </Suffix>
      </Container>
      {sendingEmail && id && (
        <SendMail docId={Number(id)} close={() => setSendingEmail(false)} />
      )}
    </>
  );
};
export default DocumentsItem;
