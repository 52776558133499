import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 530px;
  border-radius: 16px;
  background-color: #ffffff;
  border: 1px solid #dedede;
`;

export const ContainerModal = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  background: rgba(0, 0, 0, 0.7);
  z-index: 11;
`;

export const Content = styled.div`
  padding: 16px 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Text = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Footer = styled.div`
  padding: 16px 24px 16px 0;
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: flex-end;
  border-top: 1px solid #dedede;
`;
