import React, { useState, useCallback } from 'react';
import { SidebarDocumentsList } from '~/presentation/components/documents-list';
import { iClinicalDocs } from '~/presentation/roles/professional/pages/appointment/interface';
import {
  Content,
  ContentHeader,
  HeaderGoBackIcon,
  HeaderTitle,
  ContentBody,
  HeaderCloseIcon,
} from '../styles/sidebarStyles';
import { translator } from '../../i18n';
import { SelectDocumentType } from './selectDocumentType';
import { GetAppointmentById } from '~/domain/usecases/appointment/remote';

interface ClinicalDocsProps {
  handleClose: () => void;
  docs?: iClinicalDocs[];
  appointment?: GetAppointmentById.Model;
}

export enum Pages {
  LIST_DOCUMENT = 0,
  SELECT_DOCUMENT_TYPE = 1,
  CREATING_DOCUMENT = 2,
}

enum DocumentType {
  NONE = 'NONE',
  ATESTADO_MEDICO = 'MEDICALCERTIFICATE',
  RECEITA_SIMPLES = 'BASIC',
  REQUISIÇÃO_DE_EXAMES = 'EXAMREQUEST',
}

const ClinicalDocs: React.FC<ClinicalDocsProps> = ({
  handleClose,
  docs,
  appointment,
}) => {
  const [creatingDoc, setCreatingDoc] = useState(false);
  const [pageSelected, setPageSelected] = useState<Pages>(Pages.LIST_DOCUMENT);
  const [documentOpened, setDocumentOpened] = useState<DocumentType>(
    DocumentType.NONE,
  );
  const [goBackPressed, setGoBackPressed] = useState(false);

  const handleGoBackPress = useCallback(() => {
    setGoBackPressed(true);

    setTimeout(() => setGoBackPressed(false), 1000);
  }, []);

  return (
    <Content>
      <ContentHeader>
        {pageSelected !== Pages.LIST_DOCUMENT && (
          <HeaderGoBackIcon onClick={handleGoBackPress} />
        )}
        {documentOpened !== DocumentType.NONE && (
          <HeaderGoBackIcon
            onClick={() => setDocumentOpened(DocumentType.NONE)}
          />
        )}
        <HeaderTitle>{translator('Documentos Clínicos')}</HeaderTitle>
        <HeaderCloseIcon onClick={handleClose} />
      </ContentHeader>
      <ContentBody>
        {creatingDoc ? (
          <SelectDocumentType
            goBackPressed={goBackPressed}
            goBackToListDocument={() => setCreatingDoc(false)}
            pageSelected={pageSelected}
            setPageSelected={setPageSelected}
            appointment={appointment}
          />
        ) : (
          <SidebarDocumentsList
            docs={docs}
            handleCreate={() => {
              setCreatingDoc(true);
              setPageSelected(Pages.SELECT_DOCUMENT_TYPE);
            }}
            documentOpened={documentOpened}
            setDocumentOpened={setDocumentOpened}
          />
        )}
      </ContentBody>
    </Content>
  );
};

export default ClinicalDocs;
