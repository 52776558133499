import { call, put } from 'redux-saga/effects';
import { createIntl, createIntlCache } from 'react-intl';
import translations from '~/infra/i18n/locales';
import { getLocale } from '~/utils/getLocale';
import {
  UpdateFailed,
  UpdateSuccess,
} from '~/data/store/reducer/clinicalDocs/actions/update';
import { SignFailed } from '~/data/store/reducer/clinicalDocs/actions/sign';
import { iActionUpdate } from '~/domain/interfaces/redux/clinicalDocs/update';
import { SignClinicalDocs } from '~/domain/usecases/clinicalDocs/redux/SignClinicalDocs';
import {
  GetAllClinicalDocs,
  PresignClinicalDocs,
} from '~/domain/usecases/clinicalDocs/remote';
import { Sign } from '~/domain/usecases/deamon/remote';
import { makeReduxGetAllClinicalDocs } from '~/main/factories/usecases/clinicalDocs/GetAllClinicalDocsFactory';
import { makeRemotePresignClinicalDocs } from '~/main/factories/usecases/clinicalDocs/PresignClinicalDocsDetachedFactory';
import { makeRemoteUpdateClinicalDocsBySign } from '~/main/factories/usecases/clinicalDocs/UpdateClinicalDocsBySignFactory';
import { makeRemoteUpdateClinicalDocs } from '~/main/factories/usecases/clinicalDocs/UpdateClinicalDocsFactory';
import { makeRemoteSignDocument } from '~/main/factories/usecases/daemon/Sign';
import { AlertMessage } from '~/presentation/components/messages/AlertMessage';
import { iStore } from '~/domain/interfaces/models';
import storeDev from '../..';

const cache = createIntlCache();

const intl = createIntl(
  {
    locale: String(getLocale()),
    messages: translations[getLocale()],
  },
  cache,
);

export function* onUpdate(action: iActionUpdate): Generator {
  const remoteUpdateClinicalDocs = makeRemoteUpdateClinicalDocs();

  const payload = action.payload;
  const { signOnly, ...remotePayload } = payload;

  try {
    if (remotePayload.type !== 'OPINION' && !signOnly)
      yield call(remoteUpdateClinicalDocs.patch, {
        ...remotePayload,
        content: undefined,
      });

    if (
      remotePayload.certificate !== undefined &&
      remotePayload.certificateId !== undefined
    ) {
      try {
        yield SignDoc({
          docId: remotePayload.docId,
          certificate: remotePayload.certificate,
          certificateId: String(remotePayload.certificateId),
          appointmentId: remotePayload.appointment,
          isVidaas: remotePayload.signatureSource === 'VIDAAS',
        });
      } catch (e) {
        console.log('>>> Error sign: ', e);
        yield put(SignFailed());
      }
    }
    yield onUpdateSuccess(action);
    remotePayload.onSuccess?.();
    const SignLedgerResponse = SignDocumentWithLedger({
      docId: remotePayload.docId,
      certificate: remotePayload.certificate!,
      certificateId: String(remotePayload.certificateId),
      appointmentId: remotePayload.appointment,
    });
  } catch (e) {
    console.log('>>> Error update: ', e);

    yield put(UpdateFailed());
  }

  yield 10;
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onUpdateSuccess(action: iActionUpdate) {
  if (action.payload.signatureSource === 'VIDAAS') {
    makeRemotePresignClinicalDocs()
      .presign({
        docId: action.payload.docId,
        certificate: action.payload.certificate,
        signatureSource: action.payload.signatureSource,
      })
      .then(res => {
        const url = res?.dataToSign;
        localStorage.setItem('clinicalDocId', String(action.payload.docId));
        window.location.href = url;
      })
      .catch(e => console.log(e));
  }

  if (
    !window.location.pathname.includes('/conf') &&
    !window.location.pathname.includes('/opinion') &&
    action.payload.signatureSource !== 'VIDAAS'
  )
    window.location.reload();
}

export function onUpdateFailed() {
  console.log('>> UpdateClinicalDocs - Error <<');

  AlertMessage({
    type: 'danger',
    message: intl.formatMessage({ id: 'Falha ao criar documento clínico.' }),
  });
}

function* SignDocumentWithLedger(payload: SignClinicalDocs.Params) {
  const remotePresignClinicalDocs = makeRemotePresignClinicalDocs();
  const remoteSignClinicalDocs = makeRemoteSignDocument();

  if (!payload.docId) throw new Error();

  const response: PresignClinicalDocs.Model = yield call(
    remotePresignClinicalDocs.presign,
    {
      docId: payload.docId,
      certificate: payload.certificate,
      signatureSource: 'LEDGERSIGN',
    },
  );
  console.log('2. response PresignClinicalDocs', response);

  console.log('enviando...', {
    certId: payload.certificateId,
    algorithm: 'SHA256',
    dataToSignArray: [
      {
        dataToSign: response.dataToSign,
        key: 1,
      },
    ],
  });

  const responseSignDocument: Sign.Model = yield call(
    remoteSignClinicalDocs.sign,
    {
      certId: payload.certificateId,
      algorithm: 'SHA256',
      dataToSignArray: [
        {
          dataToSign: response.dataToSign,
          key: 1,
        },
      ],
    },
  );
  console.log('3. response SignDocument DEAMON', responseSignDocument);

  return responseSignDocument;
}

function* SignDoc(payload: SignClinicalDocs.Params) {
  const remoteSignClinicalDocs = makeRemoteSignDocument();
  const remoteSendSignatureValueBackend = makeRemoteUpdateClinicalDocsBySign();

  const store: iStore = storeDev.getState();
  const { orgId, orgUnitId, role } = store.auth.selectUser;
  const { info } = store.auth;

  const professionalId = info.professionals?.find(
    item => item.orgUnit.id === orgUnitId,
  )?.id;

  if (!payload.docId) throw new Error();

  const responseGetClinicalDoc: PresignClinicalDocs.Model = yield call(
    getDocDelay,
    {
      docId: payload.docId,
      certificate: payload.certificate,
      signatureSource: payload.isVidaas ? 'VIDAAS' : 'LEDGERSIGN',
    },
  );

  const signClinicalDocsBody: Sign.Params = {
    certId: payload.certificateId,
    algorithm: 'SHA256',
    dataToSignArray: [
      {
        dataToSign: responseGetClinicalDoc.dataToSign,
        key: 1,
      },
    ],
  };

  const responseSignDocument: Sign.Model = yield call(
    remoteSignClinicalDocs.sign,
    signClinicalDocsBody,
  );

  yield call(remoteSendSignatureValueBackend.patchBySign, {
    docId: payload.docId,
    signatureValue: responseSignDocument[0].signature,
  });

  yield call(getAllDocDelay, {
    filter: {
      professional: role === 'PRO' ? professionalId : undefined,
      appointment: payload.appointmentId,
      org: orgId,
      enabled: true,
    },
  });

  AlertMessage({
    message: intl.formatMessage({ id: 'Documento assinado com sucesso!' }),
    type: 'success',
  });
}

async function getAllDocDelay(data: GetAllClinicalDocs.Params): Promise<void> {
  const reduxGetAllClinicalDocs = makeReduxGetAllClinicalDocs();

  await new Promise<void>((resolve, reject) => {
    setTimeout(async () => {
      reduxGetAllClinicalDocs.getAll(data);
      resolve();
    }, 6000);
  });
}

async function getDocDelay(
  data: PresignClinicalDocs.Params,
): Promise<PresignClinicalDocs.Model> {
  const remotePresignClinicalDocs = makeRemotePresignClinicalDocs();

  const response: PresignClinicalDocs.Model = await new Promise(
    (resolve, reject) => {
      setTimeout(async () => {
        remotePresignClinicalDocs
          .presign(data)
          .then(result => resolve(result))
          .catch(e => reject(e));
      }, 6000);
    },
  );

  console.log('<><><> getDocDelay: ', response);

  return response;
}
