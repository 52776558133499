import React, { useEffect, useState } from 'react';
import { Button } from '@wisecare-tech/design-system-web';
import {
  Body,
  Container,
  DiagnoseContainer,
  DiagnosisType,
  Footer,
  InfoContainer,
  PatientInfo,
  Row,
} from './styles';
import { Menu } from '~/presentation/components/menu';
import { HeaderSimples, PageHeader } from '~/presentation/components/header';
import InfoColapse from './InfoColapse';
import ListDiagnosisFiles from './ListDiagnosisFiles';
import DeclarePendings from './DeclarePendings';
import Editor from '~/presentation/components/editor';
import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models';
import { ListDiagnosisReport } from '~/domain/usecases/diagnosisReport/remote';
import { useParams } from 'react-router-dom';
import { makeReduxActiveMessage } from '~/main/factories/usecases/message/Update';
import { closeModal } from '~/utils/closeModal';
import { calculateAgeLaudo } from '~/utils/tableDateLaudo';
import { History } from '~/main/routes';
import { AlertMessage } from '~/presentation/components/messages/AlertMessage';
import { makeRemoteEvaluateDiagnosisReport } from '~/main/factories/usecases/diagnosisReport/EvaluateDiagnosisReportFactory';
import { makeRemoteDownloadFileDiagnosisReport } from '~/main/factories/usecases/diagnosisReport/DownloadFileDiagnosisReportFactory';
import { makeRemoteDownloadZipFilesDiagnosisReport } from '~/main/factories/usecases/diagnosisReport/DownloadZipFilesDiagnosisReportFactory';

const Diagnose: React.FC = () => {
  const [openPendings, setOpenPendings] = useState(false);
  const [editorValue, setEditorValue] = useState('');
  const [data, setData] = useState<
    ListDiagnosisReport.Model['records'][0] | undefined
  >(undefined);
  const [consultant, setConsultant] = useState<
    ListDiagnosisReport.Model['records'][0]['consultantExternal'] | undefined
  >(undefined);
  const { list } = useSelector((store: iStore) => store.diagnosisReport);
  const { token } = useSelector((store: iStore) => store.auth.access);

  let { id } = useParams<{ id: string }>();

  console.log(id);

  useEffect(() => {
    const data_ = list?.records.find(item => item.id === Number(id));
    console.log('>>>', data_);
    if (data_) {
      setData(data_);
      setEditorValue(data_?.report || '');

      if (data_?.consultant) {
        setConsultant({
          fullname: `${data_?.consultant?.firstName} ${data_?.consultant?.lastName}`,
          sex: `${data_?.consultant?.sex === 'MALE' ? 'M' : 'F'}`,
          birthdate: data_?.consultant?.birthdate,
          docType: data_?.consultant?.docType,
          docValue: data_?.consultant?.docValue,
          motherName: data_?.consultant?.motherName,
          phone: data_?.consultant?.phone,
        });
      } else setConsultant(data_?.consultantExternal);
    }
  }, [list, id]);

  return (
    <Container>
      <Menu />
      <HeaderSimples title="Laudar" />
      {!openPendings ? (
        <>
          <Body>
            <Row>
              <PatientInfo>
                <Row>
                  <div className="name">
                    <strong>Nome:</strong>
                    {` ${consultant?.fullname}`}
                  </div>
                </Row>
                <Row className="justify">
                  <div className="birthdate">
                    <strong>Data de nascimento: </strong>
                    {calculateAgeLaudo(consultant?.birthdate ?? '')}
                  </div>
                  <div className="gender">
                    <strong>Sexo:</strong>{' '}
                    {`${consultant?.sex ? 'Masculino' : 'Feminino'}`}
                  </div>
                </Row>
              </PatientInfo>

              <DiagnosisType>
                <div className="title">Modalidade do laudo</div>
                <div>{data?.modality}</div>
              </DiagnosisType>
            </Row>
            <Row>
              <DiagnoseContainer>
                <div className="title">Laudo</div>
                <div className="editor">
                  <Editor value={editorValue} onChange={setEditorValue} />
                </div>
              </DiagnoseContainer>
              <InfoContainer>
                <InfoColapse
                  title="Informações do paciente"
                  children={
                    <div>{data?.consultantInfo || 'Não informado'}</div>
                  }
                ></InfoColapse>
                <InfoColapse
                  title="Subjetivo e Objetivo"
                  children={<div>{data?.description}</div>}
                ></InfoColapse>
                {data && (
                  <InfoColapse
                    title="Arquivos"
                    children={
                      <ListDiagnosisFiles
                        id={data.id}
                        files={data?.files}
                        downloadFile={(id: number, file: number) => {
                          return makeRemoteDownloadFileDiagnosisReport().downloadFile(
                            { file: file, id: id },
                          );
                        }}
                        downloadAllFiles={(id: number) => {
                          return makeRemoteDownloadZipFilesDiagnosisReport().download(
                            {
                              report: id,
                              token: token ?? '',
                            },
                          );
                        }}
                      />
                    }
                  ></InfoColapse>
                )}
              </InfoContainer>
            </Row>
          </Body>
          <Footer>
            <div>
              <Button
                size="md"
                variant="outlinedDestructive"
                onClick={() => {
                  History.push('/diagnosis');
                }}
              >
                Cancelar
              </Button>
              <Button
                size="md"
                variant="text"
                onClick={() => {
                  makeReduxActiveMessage().active({
                    active: 'sketchReporting',
                    actionOk: () => {
                      makeRemoteEvaluateDiagnosisReport()
                        .evaluate({
                          id: Number(id),
                          body: {
                            report: editorValue,
                          },
                        })
                        .then(() => {
                          AlertMessage({
                            title: 'Sucesso',
                            message: 'Esboço salvo com sucesso',
                            type: 'success',
                          });
                          closeModal();
                        })
                        .catch(() => {
                          AlertMessage({
                            title: 'Erro',
                            message: 'Erro ao salvar esboço',
                            type: 'danger',
                          });
                        });
                    },
                    actionCancel: () => {
                      closeModal();
                    },
                  });
                }}
              >
                Salvar como um esboço
              </Button>
            </div>
            <div>
              <Button
                size="md"
                variant="outlined"
                onClick={() => setOpenPendings(true)}
              >
                Declarar pendência
              </Button>
              <Button
                size="md"
                variant="primary"
                onClick={() => {
                  makeReduxActiveMessage().active({
                    active: 'evaluateReport',
                    actionOk: () => {
                      makeReduxActiveMessage().active({
                        active: 'signReport',
                        actionOk: () => {
                          // redirect to laudo list
                          // success feedback
                        },
                        actionCancel: () => closeModal,
                        data: {
                          id,
                        },
                      });
                    },
                    data: {
                      id,
                      report: editorValue,
                    },
                  });
                }}
              >
                Concluir e assinar laudo
              </Button>
            </div>
          </Footer>
        </>
      ) : (
        <DeclarePendings
          id={data?.id}
          files={data?.files}
          setOpenPendings={setOpenPendings}
        />
      )}
    </Container>
  );
};

export default Diagnose;
