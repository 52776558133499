import { ColumnDef } from '@tanstack/react-table';
import { parseISO, format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import {
  IconButton,
  SortButton,
  Tooltip,
  Typography,
} from '@wisecare-tech/design-system-web';
import storeDev from '~/data/store';
import { iStore } from '~/domain/interfaces/models';
import {
  DiagnosisReportListRecords,
  Status,
} from '~/domain/usecases/diagnosisReport/remote';
import { IconFlag } from '~/presentation/base/icons';
import { Popover } from '~/presentation/components/UI/popover';
import { priorityMap } from '~/utils/mapPriority';
import {
  calculateAgeLaudo,
  formatISODateTimeLaudo,
} from '~/utils/tableDateLaudo';
import { Information, Priority } from './styles';
import { findLastOccurrence } from '~/utils/laudoLastOccurrence';
import { iOnCallRequest } from '~/domain/interfaces/models/OnCallRequest';
import { iOnCallRequestRecords } from '~/domain/usecases/onCallRequest/remote';
import { getProfessionalInfo } from '~/utils/getProfessionalInfo';
import { makeRemoteJoinOnCallSpecialist } from '~/main/factories/usecases/onCallSpecialist/JoinOnCallSpecialistFactory';
import { History } from '~/main/routes';
import { makeReduxSetSpecialistStatusOnCall } from '~/main/factories/usecases/onCall/SetSpecialistStatusOnCallFactory';

export const columns_inprogess: ColumnDef<iOnCallRequestRecords>[] = [
  {
    accessorKey: 'requester',
    header: ({ column }) => (
      <SortButton column={column}>Profissional solicitante</SortButton>
    ),
    cell: ({ row }) => {
      const data = row.getValue(
        'requester',
      ) as iOnCallRequest['records'][0]['requester'];

      return (
        <Information>
          <Typography variant="b4_14regular">{data?.fullname}</Typography>
          <Typography variant="b4_14regular" style={{ color: '#656A6E' }}>
            {data?.specialtyName}
          </Typography>
        </Information>
      );
    },
  },
  {
    accessorKey: 'healthUnit',
    header: ({ column }) => {
      return <SortButton column={column}>Unidade de saúde</SortButton>;
    },
    cell: ({ row }) => {
      const healthUnit = row.getValue(
        'healthUnit',
      ) as iOnCallRequest['records'][0]['healthUnit'];

      return <Typography variant="b4_14regular">{healthUnit?.name}</Typography>;
    },
  },
  {
    accessorKey: 'priority',
    header: ({ column }) => <SortButton column={column}>Prioridade</SortButton>,
    cell: ({ row }) => {
      const priority = row.getValue(
        'priority',
      ) as iOnCallRequest['records'][0]['priority'];

      return (
        <Priority priority={priority}>
          <IconFlag />
          {priorityMap(priority)}
        </Priority>
      );
    },
  },
  {
    accessorKey: 'created',
    header: ({ column }) => (
      <SortButton column={column}>Solicitado em</SortButton>
    ),
    cell: ({ row }) => {
      const date = row.getValue(
        'created',
      ) as iOnCallRequest['records'][0]['created'];

      function formatDate(dateString: string): { date: string; hour: string } {
        const date = parseISO(dateString);

        return {
          date: format(date, 'dd/MM/yyyy', { locale: ptBR }),
          hour: format(date, 'HH:mm', { locale: ptBR }),
        };
      }

      return (
        <Information>
          <Typography variant="b4_14regular">
            {formatDate(date).date}
          </Typography>
          <Typography variant="b4_14regular" style={{ color: '#656A6E' }}>
            {formatDate(date).hour}
          </Typography>
        </Information>
      );
    },
  },
  {
    id: 'actions',
    enableHiding: true,
    cell: ({ row }) => {
      const value = row.original;

      // const professional_id = getProfessionalInfo();

      // const handleGoToDetails = (id: number, edit = false) => {
      //   if (edit) {
      //     history.push(`/diagnosis/${id}`, {
      //       edit,
      //     });
      //     return;
      //   }

      //   history.push(`/diagnosis/${id}`);
      // };

      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: 8,
          }}
        >
          {value.permissions?.accept && (
            <Tooltip content="Entrar na conferência" arrow>
              <IconButton
                icon="login"
                variant="primary"
                size="sm"
                onClick={() => {
                  makeRemoteJoinOnCallSpecialist()
                    .join({
                      onCallId: value.onCall?.id,
                      specialistId: value?.specialist?.id,
                      requestId: value?.id,
                    })
                    .then(response => {
                      History.getHistory().push('/conf', {
                        appointmentId: response.appointmentId,
                        onCall: {
                          sessionName: response.sessionName,
                          id: value.onCall?.id,
                          name: value.onCall?.name,
                          specialist: value.specialist?.id,
                        },
                      });
                    })
                    .catch(err => {
                      console.log('>>> Error Join Specialist: ', err);
                      makeReduxSetSpecialistStatusOnCall().setSpecialistStatus({
                        available: false,
                      });
                    });
                }}
              />
            </Tooltip>
          )}
        </div>
      );
    },
  },
];
