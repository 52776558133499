import { DownloadAllRequestFile } from '~/domain/usecases/onCallRequestFile/remote';
import {
  NotFound,
  BadRequestError,
  Forbidden,
  UnexpectedError,
  Conflict,
} from '~/domain/errors';
import { HttpClient, HttpStatusCode } from '~/data/protocols/http';
import '~/infra/global/variables';

export class RemoteDownloadAllRequestFile implements DownloadAllRequestFile {
  private readonly url: string;

  private readonly httpClient: HttpClient<DownloadAllRequestFile.Model>;

  constructor(
    url: string,
    httClient: HttpClient<DownloadAllRequestFile.Model>,
  ) {
    this.url = url;
    this.httpClient = httClient;
  }

  download = async (
    params: DownloadAllRequestFile.Params,
  ): Promise<DownloadAllRequestFile.Model> => {
    const httpResponse = await this.httpClient.request({
      url: `${this.url}/${params.onCallId}/requests/${params.requestId}/files/DOWNLOAD?accessToken=${params.body.accessToken}`,
      method: 'get',
      headers: {
        contentType: 'application/x-zip',
      },
      body: {
        accessToken: params.body,
      },
      responseType: 'blob',
    });
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body;
      case HttpStatusCode.badRequest:
        throw new BadRequestError();
      case HttpStatusCode.notFound:
        throw new NotFound();
      case HttpStatusCode.forbidden:
        throw new Forbidden();
      case HttpStatusCode.conflict:
        throw new Conflict();
      case HttpStatusCode.unauthorized:
        throw new Forbidden();
      default:
        throw new UnexpectedError();
    }
  };
}
