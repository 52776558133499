import React from 'react';
import { BrowserRouter, Route, Router, Switch } from 'react-router-dom';
import { CreateUsersConfirmationFactory } from '~/main/factories/pages/createUsersConfirmation';
import { LoginFactory } from '~/main/factories/pages/login';
import { PasswordFactory } from '~/main/factories/pages/restorePass';
import { SelectSpecialtyFactory } from '~/main/factories/pages/selectSpecialty';
import { ValidateRestorePassFactory } from '~/main/factories/pages/validateRestorePass';
import FreemiumRegister from '~/presentation/pages/FreemiumRegister';
import HealthcareFacility from '~/presentation/pages/HealthyCareFacility';
import Laudo from '~/presentation/pages/Laudo';
import { PageNotFound, Unauthorized } from '~/presentation/pages/errors/index';
import NewWaitingRoom from '~/presentation/roles/professional/pages/waitingRoom/NewWaitingRoom';
import { History } from '.';
import DutyFactory from '../factories/pages/Duty/DutyFactory';
import DutyRequesterFactory from '../factories/pages/DutyRequester/DutyRequesterFactory';
import { MakeShiftsFactory } from '../factories/pages/Shifts';
import { CreateDiagnosisReportFactory } from '../factories/pages/createDiagnosisReport';
import { ViewDiagnosticFactory } from '../factories/pages/diagnosis';
import { MakeModalTestFactory } from '../factories/pages/test';
import PrivateRoute from './PrivateRoute';
import RouterChange from './RouterChange';
import RoutesAppointment from './RoutesAppointment';
import RoutesClinicalDocs from './RoutesClinicalDocs';
import RoutesConf from './RoutesConf';
import RoutesGuest from './RoutesGuest';
import RoutesUser from './RoutesUser';
import MakeDiagnoseFactory from '../factories/pages/diagnosis/DiagnoseFactory';
import DiagnosisVidaasSignFactory from '../factories/pages/diagnosis/DiagnosisVidaasSignFactory';
import MakeNewReportFactory from '../factories/pages/reports/NewReportFactory';
import CreateRequestPage from '~/presentation/pages/CreateRequest';
import { EmergencyConsultation } from '~/presentation/components/EmergencyConsultation';
import CreateInterconsultRequestPage from '~/presentation/pages/CreateInterconsultRequest';

const Routes: React.FC = () => {
  return (
    <BrowserRouter>
      <Router history={History.getHistory()}>
        <RouterChange>
          <Switch>
            <Route exact path="/" component={LoginFactory} />
            <Route exact path="/register" component={FreemiumRegister} />
            <Route exact path="/test-room" component={NewWaitingRoom} />
            <Route
              exact
              path="/Error"
              component={() => <div>Erro no login</div>}
            />
            <PrivateRoute
              exact
              path="/duty/"
              component={SelectSpecialtyFactory}
            />
            <PrivateRoute
              exact
              path="/duty/me"
              component={DutyRequesterFactory}
            />
            <PrivateRoute
              exact
              path="/duty/create"
              component={HealthcareFacility}
            />
            <PrivateRoute
              exact
              path="/duty/:specialty"
              component={DutyFactory}
            />
            <PrivateRoute exact path="/shifts" component={MakeShiftsFactory} />
            <PrivateRoute exact path="/diagnosis" component={Laudo} />
            <PrivateRoute
              exact
              path="/diagnosis/report/:id"
              component={MakeDiagnoseFactory}
            />
            <PrivateRoute
              exact
              path="/diagnosis/create"
              component={CreateDiagnosisReportFactory}
            />
            <PrivateRoute
              exact
              path="/diagnosis/:id/details"
              component={ViewDiagnosticFactory}
            />
            <PrivateRoute
              exact
              path="/diagnosis/vidaas/sign"
              component={DiagnosisVidaasSignFactory}
            />
            <PrivateRoute
              exact
              path="/request/create"
              component={CreateRequestPage}
            />
            <PrivateRoute
              exact
              path="/request/interconsult/create"
              component={CreateInterconsultRequestPage}
            />
            <PrivateRoute
              exact
              path="/duty/:specialty/opinion/:id"
              component={MakeNewReportFactory}
            />
            <Route path="/emergency" component={EmergencyConsultation} />

            {RoutesAppointment}
            {RoutesUser}
            {RoutesGuest}
            {RoutesClinicalDocs}
            {RoutesConf}

            <Route path="/password/recovery" component={PasswordFactory} />
            <Route
              path="/user/confirmation"
              component={CreateUsersConfirmationFactory}
            />
            <Route
              path="/password/validate"
              component={ValidateRestorePassFactory}
            />

            <Route exact path="/401unauthorized" component={Unauthorized} />
            <Route exact path="/:language" component={LoginFactory} />
            <Route exact path="/modals/:id" component={MakeModalTestFactory} />
            <Route component={PageNotFound} />
          </Switch>
        </RouterChange>
      </Router>
    </BrowserRouter>
  );
};

export default Routes;
