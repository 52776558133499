import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Action,
  Container,
  Item,
  List,
  Actions,
  Info,
  File,
  FileName,
  Right,
} from './styles';
import { Input } from '~/presentation/components/UI';
import { IconSearch } from '~/presentation/base/icons';
import { ListDiagnosisReport } from '~/domain/usecases/diagnosisReport/remote';
import axios from 'axios';
import { makeApiUrl } from '~/main/factories/http';
import saveAs from 'file-saver';
import { iStore } from '~/domain/interfaces/models';
import { useSelector } from 'react-redux';
import {
  Icon,
  Button,
  IconButton,
  Typography,
  InputSearch,
} from '@wisecare-tech/design-system-web';
import { AlertMessage } from '~/presentation/components/messages/AlertMessage';
import { makeReduxListExamTypesDiagnosisReport } from '~/main/factories/usecases/diagnosisReport/ListExamTypesDiagnosisReportFactory';
import { useParams } from 'react-router-dom';
interface ownProps {
  id: number;
  files?: ListDiagnosisReport.Model['records'][0]['files'];
  downloadFile: (id: number, fileId: number) => Promise<any>;
  downloadAllFiles: (id: number) => Promise<any>;
}

interface iFiles {
  id: number;
  filename: string;
  mimetype: string;
  size: string;
  created: string;
  examType?: string;
}

const ListDiagnosisFiles: React.FC<ownProps> = ({
  id,
  files,
  downloadFile,
  downloadAllFiles,
}) => {
  const { token } = useSelector((store: iStore) => store.auth.access);
  const { records } = useSelector(
    (store: iStore) => store.diagnosisReport.examTypes,
  );

  const [search, setSearch] = useState('');

  let { specialty } = useParams<{ specialty: string }>();

  const filesFiltered = useMemo(() => {
    if (!files) return [];
    if (!search) return files;
    return (
      files?.filter(file =>
        file?.filename.toLowerCase().includes(search.toLowerCase()),
      ) ?? []
    );
  }, [files, search]);

  const downloadFileById = (fileId: number) => {
    downloadFile(id, fileId)
      .then(response => {
        const blob = new Blob([response?.data], {
          type: response?.data?.mimetype,
        });
        const urlToPdf = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = response?.data?.url ?? urlToPdf;
        link.download = response?.data?.filename ?? response?.filename;
        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);

        URL.revokeObjectURL(response?.data?.url ?? urlToPdf);

        AlertMessage({
          message: 'Arquivo baixado com sucesso!',
          type: 'success',
        });
      })
      .catch(() => {
        AlertMessage({
          message: 'Erro ao baixar arquivo!',
          type: 'danger',
        });
      });
  };

  const viewDocuments = (fileId: number) => {
    axios
      .get(
        makeApiUrl(
          `/oncall/${specialty}/requests/${id}/files/${fileId}/DOWNLOAD`,
        ),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then(response => {
        window.open(response?.data?.url, '_blank');

        AlertMessage({
          message: 'Arquivo baixado com sucesso!',
          type: 'success',
        });
      })
      .catch(() => {
        AlertMessage({
          message: 'Erro ao baixar arquivo!',
          type: 'danger',
        });
      });
  };

  const handleDownloadAllFiles = () => {
    if (!token) return;

    downloadAllFiles(id)
      .then(response => {
        const file = new Blob([response?.data], {
          type: 'application/x-zip',
        });

        const contentDisposition = response?.headers?.['content-disposition'];
        const fileNameMatch = contentDisposition?.match(/filename="(.+)"/);
        const fileName = fileNameMatch
          ? fileNameMatch?.[1]
          : `request-${id}.zip`;

        const fileURL = URL.createObjectURL(file);

        const link = document.createElement('a');
        link.href = fileURL;
        link.download = fileName;
        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);

        URL.revokeObjectURL(fileURL);

        AlertMessage({
          message: 'Arquivos baixados com sucesso!',
          type: 'success',
        });
      })
      .catch(e => {
        AlertMessage({
          message: 'Erro ao baixar todos os arquivos!',
          type: 'danger',
        });
      });
  };

  const currentExamType = useCallback(
    (examType: number) => {
      return records.find(item => item.id === examType);
    },
    [records],
  );

  const renderDocumentOfType = (mimeType: string) => {
    if (mimeType.includes('image')) {
      return <Icon name="image" color="blue-7" size="h3" />;
    }
    if (mimeType.includes('application/pdf')) {
      return <Icon name="picture_as_pdf" color="blue-7" size="h3" />;
    }
    if (mimeType.includes('audio')) {
      return <Icon name="audio_file" color="blue-7" size="h3" />;
    }
    if (mimeType.includes('video')) {
      return <Icon name="video_file" color="blue-7" size="h3" />;
    }
    return null;
  };

  const renderFiles = (file: iFiles) => {
    return (
      <File key={file?.id}>
        <Right>
          {renderDocumentOfType(file?.mimetype)}
          <FileName>
            <Typography variant="b3_14medium">{file?.filename}</Typography>
            <Typography variant="b4_14regular" style={{ color: '#656A6E' }}>
              {file?.examType
                ? currentExamType(Number(file.examType))?.descr
                : 'Não informado'}
            </Typography>
          </FileName>
        </Right>
        <Actions>
          {/* <IconButton
            icon="visibility"
            variant="primary"
            size="sm"
            onClick={() => {
              viewDocuments(file.id);
            }}
          /> */}
          <IconButton
            icon="download"
            variant="primary"
            size="sm"
            onClick={() => {
              downloadFileById(file.id);
            }}
          />
        </Actions>
      </File>
    );
  };

  useEffect(() => {
    makeReduxListExamTypesDiagnosisReport().listExamTypes({
      body: {
        dataControl: {
          limit: 9999,
        },
      },
    });
  }, []);

  return (
    <Container>
      <div className="info-row">
        <div>{`${files?.length} Arquivo${files?.length !== 1 ? 's' : ''}`}</div>
        <Button
          variant="primary"
          icon="download"
          size="sm"
          onClick={handleDownloadAllFiles}
        >
          Baixar arquivos
        </Button>
      </div>
      <InputSearch
        placeholder="Buscar arquivo"
        onChange={e => setSearch(e.target.value)}
      />
      <List>{filesFiltered?.map(file => renderFiles(file))}</List>
    </Container>
  );
};

export default ListDiagnosisFiles;
