import React from 'react';

import { History } from '~/main/routes';
import {
  Container,
  Title,
  Left,
  Right,
  Center,
  ButtonBack,
  Description,
} from './style/StyleHeaderSimples';
import { Icon } from '../icon';
import BackButton from './BackButton';
import { translator } from '../i18n';

interface ownProps {
  title: string;
  icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  value?: number;
  valueDescription?: string;
  isBack?: boolean;
}

type Props = ownProps;

const HeaderSimples: React.FC<Props> = ({
  title,
  icon,
  value,
  valueDescription,
  isBack,
}): JSX.Element => {
  const Icone = icon;
  const text = '';

  return (
    <Container>
      <Left>
        {!!isBack && (
          <BackButton
            onBack={History.getHistory().goBack}
            backDescription={translator('Voltar')}
          />
        )}
      </Left>
      <Center>
        <Title>{title}</Title>
        {Icone && <Icon src={Icone} width="16px" height="16px" />}
      </Center>
      <Right>{valueDescription ? `${value} ${valueDescription}` : ''}</Right>
    </Container>
  );
};

export default HeaderSimples;
