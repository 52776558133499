import { DownloadZipFilesDiagnosisReport } from '~/domain/usecases/diagnosisReport/remote';
import {
  NotFound,
  BadRequestError,
  Forbidden,
  UnexpectedError,
} from '~/domain/errors';
import { HttpClient, HttpStatusCode } from '~/data/protocols/http';
import '~/infra/global/variables';

export class RemoteDownloadZipFilesDiagnosisReport
  implements DownloadZipFilesDiagnosisReport
{
  private readonly url: string;

  private readonly httpClient: HttpClient<DownloadZipFilesDiagnosisReport.Model>;

  constructor(
    url: string,
    httClient: HttpClient<DownloadZipFilesDiagnosisReport.Model>,
  ) {
    this.url = url;
    this.httpClient = httClient;
  }

  download = async (
    params: DownloadZipFilesDiagnosisReport.Params,
  ): Promise<{
    data: DownloadZipFilesDiagnosisReport.Model;
    filename: string;
    mimetype: string;
  }> => {
    const httpResponse = await this.httpClient.request({
      url: `${this.url}/${params?.report}/files/DOWNLOAD?accessToken=${params.token}`,
      method: 'get',
      headers: {
        contentType: 'application/x-zip',
      },
      responseType: 'blob',
    });

    let filename = `diagnosis_report_${params.report}`;

    const contentDisposition = httpResponse.headers['content-disposition'];
    if (contentDisposition) {
      const match = contentDisposition.match(/filename="?([^"]+)"?/);
      if (match && match[1]) {
        filename = match[1];
      }
    }

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
      case HttpStatusCode.created:
      case HttpStatusCode.accepted:
      case HttpStatusCode.noContent:
        return {
          data: httpResponse.body,
          filename: filename,
          mimetype: httpResponse.headers['content-type'],
        };
      case HttpStatusCode.badRequest:
        throw new BadRequestError();
      case HttpStatusCode.notFound:
        throw new NotFound();
      case HttpStatusCode.unauthorized:
        throw new Forbidden();
      case HttpStatusCode.forbidden:
        throw new Forbidden();
      default:
        throw new UnexpectedError();
    }
  };
}
