import { DownloadFileDiagnosisReport } from '~/domain/usecases/diagnosisReport/remote';
import {
  NotFound,
  BadRequestError,
  Forbidden,
  UnexpectedError,
  BadGateway,
} from '~/domain/errors';
import { HttpClient, HttpStatusCode } from '~/data/protocols/http';
import '~/infra/global/variables';

export class RemoteDownloadFileDiagnosisReport
  implements DownloadFileDiagnosisReport
{
  private readonly url: string;

  private readonly httpClient: HttpClient<DownloadFileDiagnosisReport.Model>;

  constructor(
    url: string,
    httClient: HttpClient<DownloadFileDiagnosisReport.Model>,
  ) {
    this.url = url;
    this.httpClient = httClient;
  }

  downloadFile = async (
    params: DownloadFileDiagnosisReport.Params,
  ): Promise<{
    data: DownloadFileDiagnosisReport.Model;
    filename: string;
    mimetype: string;
  }> => {
    const httpResponse = await this.httpClient.request({
      url: this.url
        .replace('{report}', params.id.toString())
        .replace('{file}', params.file.toString()),
      responseType: 'blob',
      method: 'get',
    });

    let filename = `diagnosis_report_${params.id}_${params.file}`;

    const contentDisposition = httpResponse.headers['content-disposition'];
    if (contentDisposition) {
      const match = contentDisposition.match(/filename="?([^"]+)"?/);
      if (match && match[1]) {
        filename = match[1];
      }
    }

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
      case HttpStatusCode.created:
      case HttpStatusCode.accepted:
      case HttpStatusCode.noContent:
        return {
          data: httpResponse.body,
          filename: filename,
          mimetype: httpResponse.headers['content-type'],
        };
      case HttpStatusCode.badRequest:
        throw new BadRequestError();
      case HttpStatusCode.notFound:
        throw new NotFound();
      case HttpStatusCode.unauthorized:
        throw new Forbidden();
      case HttpStatusCode.badGateway:
        throw new BadGateway();
      default:
        throw new UnexpectedError();
    }
  };
}
