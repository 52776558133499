import styled from 'styled-components';

export const ContainerModal = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  background: rgba(0, 0, 0, 0.7);
  z-index: 11;
`;

export const Container = styled.div`
  min-width: 530px;
  max-width: 530px;
  min-height: 310px;
  height: fit-content;
  border-radius: 10px;
  border: 1px solid #dedede;
  background: #fdfdfd;
  overflow-y: auto;
`;

// Start Header Components
export const Header = styled.div`
  width: 100%;
  // height: 110px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-bottom: 1px solid #dedede;
  padding: 16px 24px;
`;

export const HeaderTitle = styled.div`
  .title {
    font-weight: 600;
    font-size: 18px;
    color: #222529;
    margin-bottom: 8px;
  }

  .text {
    font-size: 16px;
    color: #818385;
  }
`;
// End Header Components

// Start Body Components
export const Body = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  gap: 8px;
  padding: 24px 28px;
  border-bottom: 1px solid #dedede;
`;

export const LinkContainer = styled.div`
  font-size: 14px;

  .label {
    color: #818385;
    margin-bottom: 8px;
  }

  .link {
    width: 333px;
    max-width: 333px;
    text-overflow: ellipsis;
    white-space: nowrap;
    height: 40px;
    color: #818385;
    padding: 8px 16px;
    background-color: #eeeeee;
    border: 1px solid #eeeeee;
    border-radius: 6px;
    overflow: hidden;
  }
`;

export const ConsultInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  column-gap: 8px;
`;
// End Body Components

// Start Footer Components
export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 16px;
  padding: 24px;
`;
// End Footer Components

// Start Text Components
export const TextBold = styled.p`
  font-weight: 600;
  font-size: 15px;
  line-height: 150%;
  color: #222529;
`;

export const TextBoldTitle = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: #222529;
`;

export const TextNormal = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #818385;
`;

export const TextNormalBlackFour = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #62666c;
`;
// End Text Components
