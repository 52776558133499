/* eslint-disable react/jsx-one-expression-per-line */
import { format } from 'date-fns';
import React, { HTMLAttributes, ReactNode } from 'react';
import { GetAllAppointment } from '~/domain/usecases/appointment/remote';
import { IconCamAgenda, IconPin } from '~/presentation/base/icons';
import BarStatus from '~/presentation/components/BarStatusAppointment';
import {
  Box,
  Container,
  Hour,
  InfosContent,
  Place,
  PlaceText,
  Priority,
  UserName,
  YearsUnit,
} from './styles';

export interface CardProps {
  id: number;
  scheduleStatus: string;
  priority: boolean;
  name: string;
  hour: string;
  years: string;
  unit: string;
  place: string;
}

export interface MyAppointmentCardProps extends HTMLAttributes<HTMLDivElement> {
  card: GetAllAppointment.Model['results'][0];
  current: number | undefined;
}

const MyAppointmentCard: React.FC<MyAppointmentCardProps> = ({
  card,
  current,
  ...rest
}) => {
  const statusTranslations: Record<string, string> = {
    INPROGRESS: 'Em andamento',
    CANCELED: 'Cancelado',
    WAITING: 'Aguardando',
    SCHEDULED: 'Agendado',
    ENDED: 'Finalizado',
  };

  const statusPlace: Record<string, string> = {
    REMOTE: 'Teleatendimento',
    INPERSON: 'Presencial',
    VISITATION: 'Visitas Domiciliares',
  };

  const statusIcon: Record<string, ReactNode> = {
    REMOTE: <IconCamAgenda />,
    INPERSON: <IconPin />,
    VISITATION: <IconCamAgenda />,
  };

  function calculateAge(birthDate?: string) {
    const today = new Date();
    const birthDateObj = new Date(birthDate ?? new Date());

    let age = today.getFullYear() - birthDateObj.getFullYear();
    const monthDiff = today.getMonth() - birthDateObj.getMonth();

    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDateObj.getDate())
    ) {
      age -= 1;
    }

    const months = (monthDiff + 12) % 12;

    return `${age} anos`;
  }

  const fullName = card?.consultant?.firstName
    ? `${card?.consultant?.firstName} ${card?.consultant?.lastName}`
    : card?.appointment?.info?.name;

  const birthDate =
    card?.consultant?.birthDate === null
      ? card?.appointment?.info?.birthDate
      : card?.consultant?.birthDate;

  return (
    <Container
      currentActive={current === card?.appointment?.id}
      className="my-appointment-card"
      {...rest}
    >
      <Box>
        <Hour>
          {format(
            new Date(card?.appointment?.scheduled ?? new Date()),
            'HH:mm',
          )}
        </Hour>
        <InfosContent>
          <UserName>{fullName}</UserName>
          <YearsUnit>
            {card?.consultant?.birthDate && (
              <div className="dot-separate">{calculateAge(birthDate)}</div>
            )}
            {card.healthPlan?.name && (
              <div className="dot-separate">{card.healthPlan?.name}</div>
            )}
            {card.priority && (
              <div className="dot-separate">
                <Priority>Prioritário</Priority>
              </div>
            )}
          </YearsUnit>
        </InfosContent>
      </Box>
      <Box>
        <Place>
          {statusIcon[card?.appointment?.modality]}
          <PlaceText>{statusPlace[card?.appointment?.modality]}</PlaceText>
        </Place>
        <BarStatus status={card?.appointment?.status} />
      </Box>
    </Container>
  );
};

export default MyAppointmentCard;
