/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable react/jsx-indent */
import React, { useEffect, useMemo, useState, useCallback } from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import { Controller, useForm } from 'react-hook-form';
import InputMask from 'react-input-mask';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import CreatableSelect from 'react-select/creatable';
import { iMessage, iStore } from '~/domain/interfaces/models';
import { MessageOptions } from '~/domain/interfaces/redux/message';
import { Appointment } from '~/domain/usecases/appointment/remote';
import { RegionalDoc } from '~/domain/usecases/regionaldoc/remote';
import { makeRemoteCreateAppointment } from '~/main/factories/usecases/appointment/CreateAppointmentFactory';
import { makeReduxGetAllHealthUnits } from '~/main/factories/usecases/healthUnits/GetAll';
import { makeReduxActiveMessage } from '~/main/factories/usecases/message/Update';
import { makeRemoteGetAllRegionalDocs } from '~/main/factories/usecases/regionaldoc/GetAllRegionalDocs';
import {
  IconCloseModal,
  IconOtherProfessional,
  IconUserGray,
} from '~/presentation/base/icons';
import { closeModal } from '~/utils/closeModal';
import { schemaInterconsultation } from '~/validation/ineterconsulta/interconsultavalidation';
import {
  Control,
  DropdownIndicator,
  Option,
} from '~/presentation/components/filter/styles/StyledFilterAppointmentHistory';
import { Button, Select } from '../UI';
import Input from '../UI/input';
import Checkbox from '../checkbox';
import { AlertMessage } from '../messages/AlertMessage';
import { iField } from '../register/PersonalData';
import { translator } from '../i18n';
import {
  Box,
  BoxIcon,
  CheckOptions,
  CheckboxContainer,
  CheckboxText,
  Container,
  Content,
  Divider,
  Footer,
  Header,
  HeaderContent,
  InformationTitle,
  InlineInputs,
  InputContent,
  ModalContainer,
  OtherProfessional,
  PatientInfo,
  SubTitle,
  Title,
  selectStyles,
  Required,
} from './styles';
import { Label } from '../UI/select/styles';
import SearchSelect from '../UI/searchSelect';

type Props = {
  message: iMessage;
};

const InstantAppointmentModal: React.FC<Props> = ({ message }) => {
  const [instantConsultation, setInstantConsultation] = useState<Appointment>(
    {} as Appointment,
  );
  const [disabled, setDisabled] = useState(false);
  const [regionalDocs, setRegionalDocs] = useState<RegionalDoc[]>([]);
  const [selectProfessional, setSelectProfessional] = useState<
    'INSERT' | 'SELECT'
  >('INSERT');
  const [field, setField] = useState<iField>({
    name: 'CPF',
    descr: 'Cadastro de Pessoa Física',
  });
  const [currentProfessional, setCurrentProfessional] = useState('');
  const [unit, setUnit] = useState<number>();

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    resolver: zodResolver(schemaInterconsultation),
    defaultValues: {
      info: {
        name: '',
        birthDate: '',
        regType: '',
        regValue: '',
        sex: '',
      },
      professionals: [
        {
          name: '',
          unit: '',
          uf: 'PB',
        },
      ],
    },
  });

  const { active, isEmergencyConsult } = message;

  const modalName = MessageOptions.instantConsultation;
  const isOpen = active === modalName;

  const { orgId, orgUnitId } = useSelector(
    (store: iStore) => store.auth.selectUser,
  );

  const id = useSelector((state: iStore) => state.auth.info.user?.id);

  const specialty = useSelector((state: iStore) => state.auth.info);
  const { results: professionals } = useSelector(
    (state: iStore) => state.professional,
  );

  const { records: healthUnits } = useSelector(
    (store: iStore) => store.healthUnits,
  );

  const specialtyId = useMemo(() => {
    const idSpecialty = specialty?.professionals?.find(
      item => item.org.id === orgId,
    )?.professions.specialties[0].id;

    return idSpecialty;
  }, [orgId, specialty?.professionals]);

  const professionalId = useMemo(() => {
    const idProfessional = specialty?.professionals?.find(
      item => item.org.id === orgId,
    )?.id;

    return idProfessional;
  }, [orgId, specialty?.professionals]);

  const close = () => {
    reset();
    closeModal();
  };

  function transformDateFormat(inputDate: string): string {
    // Split the input date string into day, month, and year
    const [day, month, year] = inputDate.split('/');

    // Create a Date object with the given components
    const formattedDate = new Date(
      Number(year),
      Number(month) - 1,
      Number(day),
    );

    // Extract the components of the formatted date
    const formattedDay = String(formattedDate.getDate()).padStart(2, '0');
    const formattedMonth = String(formattedDate.getMonth() + 1).padStart(
      2,
      '0',
    ); // Month is zero-indexed
    const formattedYear = formattedDate.getFullYear();

    // Combine the components into the desired format
    const result = `${formattedYear}-${formattedMonth}-${formattedDay}`;

    return result;
  }

  const createInstantConsultation = () => {
    setDisabled(true);
    makeRemoteCreateAppointment()
      .create({
        org: orgId,
        orgUnit: orgUnitId,
        professional: professionalId,
        specialty: specialtyId,
        healthUnit: Number(watch('professionals[0].unit')),
        organizer: id,
        date: String(new Date()),
        professionals: [
          {
            id:
              selectProfessional === 'SELECT'
                ? Number(watch('professionals[0].name'))
                : undefined,
            name:
              selectProfessional === 'SELECT'
                ? undefined
                : watch('professionals[0].name'),
          },
        ],
        info: {
          name: watch('info.name'),
          birthDate: transformDateFormat(watch('info.birthDate')),
          regType: watch('info.regType'),
          regValue: watch('info.regValue'),
          sex: watch('info.sex'),
        },
        service: 'INSTANTANEOUS',
      })
      .then(res => {
        setDisabled(false);
        setInstantConsultation({} as Appointment);
        makeReduxActiveMessage().active({
          active: MessageOptions.instantConsultationSuccess,
          link: res.links.otherProfessionals?.[0].link,
          data: {
            professional: id,
            appointmentId: res.id,
          },
        });
      })
      .catch(() => {
        AlertMessage({
          message: 'Falha ao criar interconsulta!',
          type: 'danger',
        });
        setDisabled(false);
      });
  };

  const defaultValueRegionalDocs = useMemo(() => {
    const doc = regionalDocs.find(item => item.name === watch('info.regType'));

    return doc;
  }, [regionalDocs, watch('info.regType')]);

  const generateHealthUnitSelectOptions = useCallback(() => {
    return _.sortBy(
      healthUnits?.flatMap(value => {
        return [
          {
            value: value.id,
            label: `${value.name} [${value.city.name}]`,
          },
        ];
      }),
      item => item?.label.replace(/[^a-zA-Z0-9]/g, '').toLocaleLowerCase(),
    );
  }, [healthUnits]);

  const generateProfessionalSelectOptions = useCallback(() => {
    return _.sortBy(
      professionals?.flatMap(value => {
        return [
          {
            value: value.professional.id.toString(),
            label: `${value.user.firstName} ${value.user.lastName}`,
          },
        ];
      }),
      item => item?.label.replace(/[^a-zA-Z0-9]/g, '').toLocaleLowerCase(),
    );
  }, [professionals]);

  const healthUnitMultiselectSelected = useMemo(() => {
    return generateHealthUnitSelectOptions().find(
      option => option?.value === Number(watch('professionals[0].unit')),
    );
  }, [generateHealthUnitSelectOptions, watch('professionals[0].unit')]);

  const professionalMultiselectSelected = useMemo(() => {
    return generateProfessionalSelectOptions().find(
      option => option.value === watch('professional'),
    );
  }, [generateProfessionalSelectOptions, watch]);

  useEffect(() => {
    if (isOpen) {
      makeRemoteGetAllRegionalDocs()
        .getAll({
          disablePagination: true,
          country: 'BRA',
        })
        .then(res => {
          setRegionalDocs(res.records);
        })
        .catch(err => {
          console.log('err: ', err);
        });

      makeReduxGetAllHealthUnits().getAll({
        limit: 9999,
      });
    }
  }, [isOpen]);

  useEffect(() => {
    if (defaultValueRegionalDocs) {
      setField({
        name: defaultValueRegionalDocs.name,
        descr: defaultValueRegionalDocs.descr,
        mask: defaultValueRegionalDocs.mask,
        id: defaultValueRegionalDocs.id,
        regex: defaultValueRegionalDocs.regex,
      });
    }
  }, [defaultValueRegionalDocs]);

  console.log(watch());

  return isOpen ? (
    <ModalContainer>
      <Container onSubmit={handleSubmit(createInstantConsultation)}>
        <Header>
          <HeaderContent>
            <Title>
              {isEmergencyConsult
                ? 'Criar interconsulta de acompanhamento'
                : 'Criar interconsulta instantânea'}
            </Title>
            <BoxIcon onClick={closeModal}>
              <IconCloseModal />
            </BoxIcon>
          </HeaderContent>
          <SubTitle>
            {isEmergencyConsult
              ? 'Preencha os dados abaixo para criar a interconsulta para este paciente. É possível editá-los após a criação.'
              : 'Preencha os dados abaixo para criar a interconsulta. É possível editá-los após a criação.'}
          </SubTitle>
        </Header>
        <Divider />
        <Content>
          <PatientInfo>
            <InformationTitle style={{ marginBottom: 16 }}>
              <IconUserGray />
              Informações do paciente
            </InformationTitle>
            <InputContent>
              <Controller
                control={control}
                name="info.name"
                render={({ onChange, value }) => (
                  <Input
                    height="40px"
                    label="Nome do paciente"
                    required
                    onChange={e => onChange(e.target.value)}
                    value={value}
                    error={
                      Boolean(errors.info?.name) &&
                      Boolean(errors.info?.name?.message)
                    }
                    message={
                      Boolean(errors.info?.name) &&
                      Boolean(errors.info?.name?.message)
                        ? String(errors.info?.name?.message)
                        : ''
                    }
                  />
                )}
              />
              <InlineInputs>
                <Controller
                  control={control}
                  name="info.sex"
                  render={({ value, onChange }) => (
                    <Select
                      height="40px"
                      width="50%"
                      label="Sexo"
                      required
                      onChange={e => {
                        onChange(e.target.value);
                      }}
                      value={value}
                      error={
                        Boolean(errors.info?.sex) &&
                        Boolean(errors.info?.sex?.message)
                      }
                      message={
                        Boolean(errors.info?.sex) &&
                        Boolean(errors.info?.sex?.message)
                          ? String(errors.info?.sex?.message)
                          : ''
                      }
                    >
                      <option value="">Selecione</option>
                      <option value="M">Masculino</option>
                      <option value="F">Feminino</option>
                    </Select>
                  )}
                />

                <Controller
                  control={control}
                  name="info.birthDate"
                  render={({ onChange, value }) => (
                    <InputMask
                      mask="99/99/9999"
                      name="birthdate"
                      className="birthdate"
                      value={value}
                      onChange={e => {
                        onChange(e.target.value.trim());
                      }}
                    >
                      <Input
                        height="40px"
                        width="50%"
                        label="Data de nascimento"
                        required
                        error={
                          Boolean(errors.info?.birthDate) &&
                          Boolean(errors.info?.birthDate?.message)
                        }
                        message={
                          Boolean(errors.info?.birthDate) &&
                          Boolean(errors.info?.birthDate?.message)
                            ? String(errors.info?.birthDate?.message)
                            : ''
                        }
                      />
                    </InputMask>
                  )}
                />
              </InlineInputs>
              <InlineInputs>
                <Controller
                  control={control}
                  name="info.regType"
                  render={({ onChange, value }) => (
                    <Select
                      height="40px"
                      width="50%"
                      label="Documento"
                      required
                      onChange={e => {
                        onChange(e.target.value);
                        setValue('info.regValue', '');
                      }}
                      value={value}
                      error={
                        Boolean(errors.info?.regType) &&
                        Boolean(errors.info?.regType?.message)
                      }
                      message={
                        Boolean(errors.info?.regType) &&
                        Boolean(errors.info?.regType?.message)
                          ? String(errors.info?.regType?.message)
                          : ''
                      }
                    >
                      <option value="">Selecione</option>
                      {regionalDocs.length &&
                        regionalDocs.map(item => (
                          <option
                            id={`registerOption_${item.id}`}
                            value={item.name}
                          >
                            {item.name}
                          </option>
                        ))}
                    </Select>
                  )}
                />
                <Controller
                  control={control}
                  name="info.regValue"
                  render={({ onChange, value }) => (
                    <>
                      {field?.mask === '99-99999999-9' ||
                      field?.mask === '999.999.999-99' ? (
                        <InputMask
                          mask={field?.mask}
                          name="regValue"
                          className="regValue"
                          value={value}
                          onChange={e => {
                            onChange(e.target.value.trim());
                          }}
                        >
                          <Input
                            height="40px"
                            width="50%"
                            label="Número do documento"
                            required
                            error={
                              field?.mask?.replace(/[^a-zA-Z0-9*]/g, '')
                                .length !==
                              value.replace(/[^a-zA-Z0-9*]/g, '').length
                            }
                            message={
                              field?.mask?.replace(/[^a-zA-Z0-9*]/g, '')
                                .length !==
                              value.replace(/[^a-zA-Z0-9*]/g, '').length
                                ? 'Documento inválido'
                                : ''
                            }
                          />
                        </InputMask>
                      ) : (
                        <Input
                          height="40px"
                          width="50%"
                          label="Número do documento"
                          placeholder="0000000000"
                          required
                          onChange={e => onChange(e.target.value)}
                          value={value}
                          error={
                            Boolean(errors.info?.regValue) &&
                            Boolean(errors.info?.regValue?.message)
                          }
                          message={
                            Boolean(errors.info?.regValue) &&
                            Boolean(errors.info?.regValue?.message)
                              ? String(errors.info?.regValue?.message)
                              : ''
                          }
                        />
                      )}
                    </>
                  )}
                />
              </InlineInputs>
            </InputContent>
          </PatientInfo>
          <OtherProfessional>
            <InformationTitle style={{ marginBottom: 16 }}>
              <IconOtherProfessional />
              Informações do profissional convidado
            </InformationTitle>
            <InlineInputs>
              <Controller
                control={control}
                name="professionals[0].uf"
                render={({ value }) => (
                  <Select
                    name="professionals[0].uf"
                    label="UF"
                    width="25%"
                    height="40px"
                    value={value}
                    disabled
                    error={Boolean(errors.professionals?.[0]?.uf)}
                    placeholder="Estado"
                  >
                    <option value="PB">PB</option>
                  </Select>
                )}
              />
              <div style={{ width: '75%' }}>
                <div style={{ display: 'flex', marginBottom: 4 }}>
                  <Label>Unidade de saúde*</Label>
                </div>
                <div style={{ width: '100%' }}>
                  <Controller
                    control={control}
                    name="professionals[0].unit"
                    render={({ onChange, value }) => (
                      <CreatableSelect
                        className="select"
                        components={{
                          Control,
                          DropdownIndicator,
                          IndicatorSeparator: () => null,
                          Option,
                        }}
                        controlShouldRenderValue
                        placeholder="Selecione a unidade"
                        formatCreateLabel={(label: string) =>
                          `Buscar por ${label}`
                        }
                        options={generateHealthUnitSelectOptions()}
                        value={healthUnitMultiselectSelected}
                        onChange={e => {
                          console.log(e?.label);
                          onChange(e?.label, { shouldValidate: true });
                          setValue('professionals[0].unit', String(e?.value));
                        }}
                        styles={selectStyles(!!errors.professionals?.[0]?.unit)}
                        isValidNewOption={() => false}
                        noOptionsMessage={() => 'Nenhum resultado encontrado'}
                      />
                    )}
                  />
                  {errors.professionals?.[0]?.unit && (
                    <Required>
                      {errors.professionals?.[0]?.unit?.message}
                    </Required>
                  )}
                </div>
              </div>
            </InlineInputs>
            <CheckOptions
              style={{ marginTop: errors.professionals?.[0]?.unit ? 26 : 16 }}
            >
              <CheckboxContainer>
                <Checkbox
                  checked={selectProfessional === 'INSERT'}
                  onClick={() => {
                    setSelectProfessional('INSERT');
                    setCurrentProfessional('');
                    setValue('professionals[0].name', '');
                    setValue('professionals[0].regValue', '');
                  }}
                />
                <CheckboxText>Inserir os dados do profissional</CheckboxText>
              </CheckboxContainer>
              <CheckboxContainer>
                <Checkbox
                  checked={selectProfessional === 'SELECT'}
                  onClick={() => {
                    setSelectProfessional('SELECT');
                    setValue('professionals[0].name', '');
                    setValue('professionals[0].regValue', '');
                  }}
                />
                <CheckboxText>Selecionar profissional cadastrado</CheckboxText>
              </CheckboxContainer>
            </CheckOptions>
            <InputContent>
              <div>
                <Controller
                  control={control}
                  name="professionals[0].name"
                  render={({ onChange, value }) =>
                    selectProfessional === 'INSERT' ? (
                      <Input
                        height="40px"
                        onChange={e => onChange(e.target.value)}
                        value={value}
                        label="Nome do profissional convidado"
                        required
                        error={
                          Boolean(errors.professionals?.[0]?.name) &&
                          Boolean(errors.professionals?.[0]?.name?.message)
                        }
                        message={
                          Boolean(errors.professionals?.[0]?.name) &&
                          Boolean(errors.professionals?.[0]?.name?.message)
                            ? String(errors.professionals?.[0]?.name?.message)
                            : ''
                        }
                      />
                    ) : (
                      <SearchSelect
                        height="40px"
                        label="Selecione um profissional cadastrado"
                        placeholder={translator('Selecione um profissional')}
                        required
                        onChange={e => {
                          onChange(e?.value);
                        }}
                        error={
                          Boolean(errors.professionals?.[0]?.name) &&
                          Boolean(errors.professionals?.[0]?.name?.message)
                        }
                        message={
                          Boolean(errors.professionals?.[0]?.name) &&
                          Boolean(errors.professionals?.[0]?.name?.message)
                            ? String(errors.professionals?.[0]?.name?.message)
                            : ''
                        }
                        options={generateProfessionalSelectOptions()}
                        value={professionalMultiselectSelected}
                      />
                    )
                  }
                />
              </div>
            </InputContent>
          </OtherProfessional>
        </Content>
        <Divider />
        <Box
          style={{
            backgroundColor: '#F8F8F8',
            borderBottomLeftRadius: 16,
            borderBottomRightRadius: 16,
          }}
        >
          <Footer>
            <Button
              variant="secundary"
              type="button"
              style={{ width: '233px' }}
              onClick={close}
            >
              Cancelar
            </Button>
            <Button
              type="submit"
              style={{ width: '233px' }}
              disabled={disabled}
            >
              Criar solicitação
            </Button>
          </Footer>
        </Box>
      </Container>
    </ModalContainer>
  ) : null;
};

export default InstantAppointmentModal;
