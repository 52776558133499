import React, { useEffect, useState } from 'react';
import {
  Container,
  Content,
  Footer,
  ContainerModal,
  Text,
} from '../evaluateMedicalOpinion/styles';
import { Typography, Button } from '@wisecare-tech/design-system-web';
import { iMessage } from '~/domain/interfaces/models';
import { MessageOptions } from '~/domain/interfaces/redux/message';
import { closeModal } from '~/utils/closeModal';
import { makeRemoteStopAppointment } from '~/main/factories/usecases/appointment/StopAppointmentFactory';
import { AlertMessage } from '~/presentation/components/messages/AlertMessage';
import { Signature } from '~/presentation/components/signature';
import { makeRemotePresignClinicalDocs } from '~/main/factories/usecases/clinicalDocs/PresignClinicalDocsDetachedFactory';
import { makeReduxUpdateClinicalDocs } from '~/main/factories/usecases/clinicalDocs/UpdateClinicalDocsFactory';

interface ownProps {
  message: iMessage;
}

const SignMedicalOpinion: React.FC<ownProps> = ({ message }) => {
  const { active, actionCancel, actionOk, data } = message;

  const msgName = MessageOptions.signMedicalOpinion;

  const [signNow, setSignNow] = useState<boolean | undefined>(false);
  const [signatureData, setSignatureData] = useState<{
    certificateId: string;
    certificate: string;
    signatureSource?: string;
  }>();
  const [disableButton, setDisableButton] = useState<boolean>(false);

  const stopAppointment = () => {
    makeRemoteStopAppointment()
      .stop({
        id: data.id,
        outcome: data.outcome,
      })
      .then(() => {
        AlertMessage({
          title: 'Sucesso',
          message: 'Atendimento finalizado com sucesso',
          type: 'success',
        });
        actionOk?.();
      })
      .catch(() => {
        AlertMessage({
          title: 'Erro',
          message: 'Erro ao finalizar o atendimento',
          type: 'danger',
        });
        actionCancel?.();
      });
  };

  const handleSubmit = () => {
    if (!signNow) {
      AlertMessage({
        title: 'Aguardando assinatura',
        message:
          'Você selecionou a opção de assinar depois, seu documento foi movido para a aba de “Aguardando parecer”.',
        type: 'info',
      });
      actionOk?.();
      return;
    }

    if (signatureData?.signatureSource === 'VIDAAS') {
      makeRemotePresignClinicalDocs()
        .presign({
          docId: data?.id,
          signatureSource: signatureData?.signatureSource ?? undefined,
          certificate: signatureData?.certificate,
        })
        .then(res => {
          const url = res.dataToSign;
          localStorage.setItem('diagnosisReportId', data?.id);
          window.location.href = url;

          stopAppointment();
        })
        .catch(err => {
          console.log('>>> Err 1: ', err);
        });
    } else {
      makeReduxUpdateClinicalDocs().update({
        certificate: signatureData?.certificate ?? '',
        certificateId: signatureData?.certificateId ?? '',
        docId: data?.docId,
        appointment: data?.id,
        type: 'OPINION',
        content: undefined,
        signatureSource: signatureData?.signatureSource ?? undefined,
        onSuccess: () => {
          AlertMessage({
            title: 'Sucesso',
            message: 'Documento assinado com sucesso',
            type: 'success',
          });
          stopAppointment();
        },
      });
    }
  };

  useEffect(() => {
    if (msgName !== active) {
      setSignNow(false);
      setSignatureData(undefined);
      setDisableButton(false);
    }
  }, [active]);

  return (
    <>
      {msgName === active && (
        <ContainerModal>
          <Container>
            <Content>
              <Text>
                <Typography
                  variant="t1_18semibold"
                  style={{ fontFamily: 'Roboto' }}
                >
                  Assinar o parecer
                </Typography>
                <Typography
                  variant="b1_16regular"
                  style={{
                    color: '#656A6E',
                    fontFamily: 'Roboto',
                    fontSize: '16px',
                  }}
                >
                  Selecione seu assinador e depois seu certificado digital para
                  finalizar a assinatura do documento.
                </Typography>
              </Text>

              <Signature
                changeSelected={(e: {
                  certificateId: string;
                  certificate: string;
                  signatureSource?: string;
                }) =>
                  setSignatureData({
                    certificate: e.certificate,
                    certificateId: e.certificateId,
                    signatureSource: e.signatureSource,
                  })
                }
                changeDisabled={(state: boolean) => setDisableButton(state)}
                changeSignNow={(state: boolean) => setSignNow(state)}
                onSignSubmit={handleSubmit}
              />
            </Content>
            <Footer>
              <Button variant="secondary" onClick={actionCancel && closeModal}>
                Cancelar{' '}
              </Button>
              <Button
                variant="primary"
                onClick={handleSubmit}
                disabled={disableButton}
              >
                {signNow ? 'Assinar' : 'Concluir'}
              </Button>
            </Footer>
          </Container>
        </ContainerModal>
      )}
    </>
  );
};

export default SignMedicalOpinion;
