/* eslint-disable react/react-in-jsx-scope */
import { createRef, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { iStore } from '~/domain/interfaces/models';
import { iOnCallRequest } from '~/domain/interfaces/models/OnCallRequest';
import { makeReduxActiveMessage } from '~/main/factories/usecases/message/Update';
import { makeReduxSetSpecialistStatusOnCall } from '~/main/factories/usecases/onCall/SetSpecialistStatusOnCallFactory';
import { makeRemoteAvailableOnCallSpecialist } from '~/main/factories/usecases/onCallSpecialist/AvailableOnCallSpecialistFactory';
import { DutyRequestDetailsCard } from '~/presentation/components/DutyRequestDetailsCard';
import { getProfessionalInfo } from '~/utils/getProfessionalInfo';
import {
  ActionsContainer,
  Content,
  SwitchContainer,
  Wrapper,
  ContainerEmpty,
  ContainerEmptyIcon,
  Texts,
} from './styles';
import {
  Button,
  Chips,
  Icon,
  InputSearch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  Tabs,
  TabsList,
  TabsTrigger,
  Typography,
} from '@wisecare-tech/design-system-web';
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import { columns_waiting } from './columns/columns_waiting';
import { columns_inprogess } from './columns/columns_progress';
import { columns_clinicalopnion } from './columns/columns_reporting';
import { columns_attendence } from './columns/columns_monitoring';
import { columns_finalized } from './columns/columns_closed';
import { makeReduxListOnCallRequest } from '~/main/factories/usecases/onCallRequest/ListOnCallRequestFactory';
import { closeModal } from '~/utils/closeModal';
import { makeRemoteUnavailableOnCallSpecialist } from '~/main/factories/usecases/onCallSpecialist/UnavailableOnCallSpecialistFactory';

export type Status =
  | 'WAITING'
  | 'PROGRESS'
  | 'CLOSED'
  | 'REPORTING'
  | 'MONITORING';

type TabStats = 'ALL' | 'MY_APPOINTMENTS';

const DutyPage: React.FC = () => {
  const [filterStatus, setFilterStatus] = useState<Status>('WAITING');
  const [tabSelected, setTabSelected] = useState<TabStats>('ALL');
  const [sorting, setSorting] = useState<SortingState>([]);
  const [selectedRequest, setSelectedRequest] = useState<
    iOnCallRequest['records'][0] | null
  >(null);

  const data = useSelector((store: iStore) => store.onCallRequest);
  const { orgUnitId } = useSelector((store: iStore) => store.auth.selectUser);

  const table_waiting = useReactTable({
    data: data?.records?.filter(item => item.status === 'WAITING') ?? [],
    columns: columns_waiting,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      sorting,
    },
  });

  const table_inprogress = useReactTable({
    data: data?.records?.filter(item => item.status === 'PROGRESS') ?? [],
    columns: columns_inprogess,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      sorting,
    },
  });

  const table_clinicalopnion = useReactTable({
    data: data?.records?.filter(item => item.status === 'REPORTING') ?? [],
    columns: columns_clinicalopnion,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      sorting,
    },
  });

  const table_attendence = useReactTable({
    data: data?.records?.filter(item => item.status === 'MONITORING') ?? [],
    columns: columns_attendence,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      sorting,
    },
  });

  const table_finalized = useReactTable({
    data: data?.records?.filter(item => item.status === 'CLOSED') ?? [],
    columns: columns_finalized,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      sorting,
    },
  });

  const renderTable = {
    WAITING: table_waiting,
    PROGRESS: table_inprogress,
    REPORTING: table_clinicalopnion,
    MONITORING: table_attendence,
    CLOSED: table_finalized,
  };

  const { state } = useLocation<{
    specialty: string;
    id: string;
    leaveConf: boolean;
  }>();

  const switchRef = createRef<HTMLInputElement>();
  // const { data?.records } = useSelector((store: iStore) => store.duty);

  const handleFilterStatus = (
    value: Status = 'WAITING',
    tabValue: TabStats = 'ALL',
  ) => {
    makeReduxListOnCallRequest().list({
      body: {
        filters: {
          status: value,
          specialist: tabValue === 'ALL' ? undefined : professional?.id,
          onCall: Number(state?.id),
        },
        orderBy: [
          {
            attribute: 'CREATED',
            direction: 'DESC',
          },
        ],
      },
    });
  };

  const professional = getProfessionalInfo();

  const handleStatus = (status?: boolean) => {
    if (professional)
      if (!status)
        makeRemoteAvailableOnCallSpecialist()
          .available({
            onCallId: Number(state?.id),
            specialistId: professional?.id,
          })
          .then(() =>
            makeReduxSetSpecialistStatusOnCall().setSpecialistStatus({
              available: !status,
            }),
          );
      else
        makeRemoteUnavailableOnCallSpecialist()
          .unavailable({
            onCallId: Number(state?.id),
            specialistId: professional?.id,
          })
          .then(() =>
            makeReduxSetSpecialistStatusOnCall().setSpecialistStatus({
              available: !status,
            }),
          )
          .catch(err => {
            console.log('Error pausing: ', err);
          });
  };

  const info = useMemo(() => {
    const requester = data?.records?.find(
      requesterItem => requesterItem.id === selectedRequest?.id,
    );

    if (requester) {
      return requester.requester;
    }
    return undefined;
  }, [data?.records, selectedRequest]);

  useEffect(() => {
    makeReduxListOnCallRequest().list({
      body: {
        filters: {
          status: 'WAITING',
          onCall: Number(state?.id),
        },
        orderBy: [
          {
            attribute: 'CREATED',
            direction: 'DESC',
          },
        ],
      },
    });
  }, [orgUnitId]);

  return (
    <Wrapper>
      <ActionsContainer>
        <div style={{ display: 'flex', gap: 16 }}>
          <Chips
            text="Todos"
            style={{ height: 40 }}
            variant={tabSelected === 'ALL' ? 'blue' : 'white'}
            onClick={() => {
              setTabSelected('ALL');
              handleFilterStatus(filterStatus, 'ALL');
            }}
          />
          <Chips
            text="Meus atendimentos"
            style={{ height: 40 }}
            variant={tabSelected === 'MY_APPOINTMENTS' ? 'blue' : 'white'}
            onClick={() => {
              setTabSelected('MY_APPOINTMENTS');
              handleFilterStatus(filterStatus, 'MY_APPOINTMENTS');
            }}
          />
        </div>
        <div style={{ display: 'flex', alignItems: 'center', gap: 16 }}>
          <InputSearch placeholder="Pesquisar" style={{ width: '320px' }} />
          <Button
            variant="secondary"
            size="md"
            icon="video_call"
            onClick={() => {
              makeReduxActiveMessage().active({
                active: 'emergencyConsultation',
                actionCancel: () => {
                  closeModal();
                },
              });
            }}
          >
            Interconsulta emergencial
          </Button>
        </div>
      </ActionsContainer>
      <Content>
        <div
          style={{
            width: '100%',
            height: '640px',
            maxHeight: '640px',
          }}
        >
          <Tabs
            defaultValue="waiting"
            style={{
              border: '1px solid #dedede',
              borderRadius: 4,
              borderBottom: 'none',
            }}
          >
            <TabsList>
              <TabsTrigger
                onClick={() => {
                  handleFilterStatus('WAITING', tabSelected);
                  setFilterStatus('WAITING');
                }}
                value="waiting"
              >
                Aguardando ({data?.overview?.WAITING})
              </TabsTrigger>
              <TabsTrigger
                onClick={() => {
                  handleFilterStatus('PROGRESS', tabSelected);
                  setFilterStatus('PROGRESS');
                }}
                value="progress"
              >
                Em andamento ({data?.overview?.PROGRESS})
              </TabsTrigger>
              <TabsTrigger
                onClick={() => {
                  handleFilterStatus('REPORTING', tabSelected);
                  setFilterStatus('REPORTING');
                }}
                value="reporting"
              >
                Aguardando parecer ({data?.overview?.REPORTING})
              </TabsTrigger>
              <TabsTrigger
                onClick={() => {
                  handleFilterStatus('MONITORING', tabSelected);
                  setFilterStatus('MONITORING');
                }}
                value="monitoring"
              >
                Acompanhamento ({data?.overview?.MONITORING})
              </TabsTrigger>
              <TabsTrigger
                onClick={() => {
                  handleFilterStatus('CLOSED', tabSelected);
                  setFilterStatus('CLOSED');
                }}
                value="closed"
              >
                Finalizados ({data?.overview?.CLOSED})
              </TabsTrigger>
            </TabsList>
          </Tabs>
          <Table style={{ border: '1px solid #dedede' }}>
            <TableHeader>
              {renderTable[filterStatus as Status]
                .getHeaderGroups()
                .map(headerGroup => (
                  <>
                    {headerGroup.headers.map(header => (
                      <TableHead key={header.id}>
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext(),
                            )}
                      </TableHead>
                    ))}
                  </>
                ))}
            </TableHeader>
            <TableBody>
              {renderTable[filterStatus as Status].getRowModel()?.rows
                ?.length ? (
                <>
                  {renderTable[filterStatus as Status]
                    .getRowModel()
                    .rows.map(row => (
                      <TableRow
                        key={row.id}
                        selected={row.original.id === selectedRequest?.id}
                      >
                        {row.getAllCells().map(cell => (
                          <TableCell
                            key={cell.id}
                            onClick={() =>
                              setSelectedRequest(
                                cell.row
                                  .original as iOnCallRequest['records'][0],
                              )
                            }
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext(),
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                </>
              ) : (
                <TableRow style={{ height: '595px', maxHeight: '595px' }}>
                  <TableCell
                    colSpan={
                      renderTable[filterStatus as Status]?.getAllColumns()
                        .length
                    }
                    className="h-24 text-center"
                  >
                    <ContainerEmpty>
                      <ContainerEmptyIcon>
                        <Icon name="error" size="h3" color="white-7" />
                      </ContainerEmptyIcon>
                      <Texts>
                        <Typography variant="b3_14medium">
                          Nenhum atendimento
                        </Typography>
                        <Typography
                          variant="b4_14regular"
                          style={{ color: '#656A6E' }}
                        >
                          Até o momento, nenhum atendimento nessa etapa.
                        </Typography>
                      </Texts>
                    </ContainerEmpty>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <DutyRequestDetailsCard
          info={selectedRequest}
          selectedRequester={!!selectedRequest}
          variant="SPECIALIST"
          setSelectedRequest={setSelectedRequest}
        />
      </Content>
    </Wrapper>
  );
};

export default DutyPage;
