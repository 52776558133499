import styled from 'styled-components';

export const ContainerModal = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  background: rgba(0, 0, 0, 0.7);
  z-index: 11;
`;

export const Container = styled.div`
  width: 667px;
  max-height: 662px;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  background: #ffffff;
  padding-bottom: 40px;
`;

export const MessageExpired = styled.h1`
  color: #444a51;
  font-weight: bold;
  text-align: center;
  font-size: 24px;
`;

export const Message = styled.span`
  text-align: center;
  width: 90%;
`;

export const Information = styled.div`
  margin-top: 31px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 31px;
`;

export const Action = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CarrouselDiv = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  background: #b0bcc4;
  height: 300px;
  max-height: 300px;
  border-radius: 5px 5px 0 0;
`;

export const FremiumDiv = styled.div`
  max-height: 300px;
  height: 300px;
  border-radius: 5px 5px 0 0;
  background: #b0bcc4;
  display: flex;
  align-items: center;
  justify-content: center;
`;
