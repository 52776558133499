import {
  Button,
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
  Typography,
} from '@wisecare-tech/design-system-web';
import React, { useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useLocation, useParams } from 'react-router';
import { makeRemoteUpdateDiagnosisReport } from '~/main/factories/usecases/diagnosisReport/UpdateDiagnosisReportFactory';
import { makeReduxActiveMessage } from '~/main/factories/usecases/message/Update';
import { History } from '~/main/routes';
import { Menu } from '~/presentation/components/menu';
import { AlertMessage } from '~/presentation/components/messages/AlertMessage';
import { useRemoteReport } from '~/presentation/hooks/remoteReport';
import { useGetDiagnosisContext } from '~/presentation/hooks/remoteReport/loadData';
import { closeModal } from '~/utils/closeModal';
import { EditDiagnosisReport } from '~/validation/validators/editDiagnosisReport';
import { ReturnHistory } from './history';
import { FilesSection } from './sections/Files';
import { MedicalReportSection } from './sections/MedicalReport';
import { PatientSection } from './sections/Patient';
import { ResponsibleSection } from './sections/Responsible';
import {
  BodyContainer,
  ButtonsContainerSecondary,
  Container,
  Content,
  Footer,
  FooterContent,
  SectionsContainer,
  Subheader,
} from './styles';

interface StateParams {
  canEdit: boolean;
  canDownload: boolean;
}

export const RemoteReportView: React.FC = () => {
  const { disableField, startEdit, cancelEdit } = useRemoteReport();
  const { getValues, handleSubmit } = useFormContext<EditDiagnosisReport>();
  const submitButtonRef = useRef<HTMLButtonElement>(null);

  const [tab, setTab] = useState<string>('request');
  const [loading, setLoading] = useState<boolean>(false);

  const { canDownload, canEdit } = useLocation<StateParams>().state;

  const { diagnosisData } = useGetDiagnosisContext();

  const url = new URL(window.location.href);

  const handleUrl = (tab: string) => {
    url.searchParams.set('tab', tab);
    window.history.replaceState({}, '', url.toString());
  };

  const defaultTab = url.searchParams.get('tab') ?? 'request';

  const submitForm = async () => {
    const updateDiagnosisReport = makeRemoteUpdateDiagnosisReport();
    const values = getValues();

    const formattedFiles =
      values.files?.map(file => ({
        id: file?.id,
        examType: file?.examType,
      })) ?? [];

    try {
      await updateDiagnosisReport
        .update({
          query: {
            id: diagnosisData?.id ?? 0,
          },
          body: {
            healthUnit: values.healthUnit?.id,
            modality: values.modality?.id,
            specialty: values.specialty.id,
            specialist: values.specialist?.id,
            priority: values.priority?.value,
            consultantInfo: values.consultantInfo ?? undefined,
            files: formattedFiles,
            status: 'SUBMITTED',
            description: values.description,
            ...(values?.consultantExternal?.id
              ? {
                  consultant: values.consultantExternal.id,
                }
              : {
                  consultantExternal: {
                    // birthdate: values.consultantExternal?.birthdate,
                    birthdate: '2002-01-01',
                    docValue: values.consultantExternal?.docValue,
                    mothername: values.consultantExternal?.mothername,
                    phone: values.consultantExternal?.phone,
                    fullname: values.consultantExternal?.fullname,
                    sex: values.consultantExternal?.sex?.value,
                    docType: values.consultantExternal?.docType?.name,
                  },
                }),
          },
        })
        .then(() => {
          AlertMessage({
            type: 'success',
            message: 'Solicitação enviada com sucesso',
          });

          History.push('/diagnosis');
        });
      cancelEdit();
    } catch (err) {
      console.log('error', err);
    }
  };

  const sketchDiagnosisReport = async () => {
    const updateDiagnosisReport = makeRemoteUpdateDiagnosisReport();
    const values = getValues();

    const formattedFiles =
      values.files?.map(file => {
        console.log('file', file);
        return {
          ...file,
          id: file?.id,
          examType: file?.examType,
        };
      }) ?? [];

    try {
      await updateDiagnosisReport
        .update({
          query: {
            id: diagnosisData?.id ?? 0,
          },
          body: {
            healthUnit: values.healthUnit?.id,
            modality: values.modality?.id,
            specialty: values.specialty.id,
            specialist: values.specialist?.id,
            priority: values.priority?.value,
            consultantInfo: values.consultantInfo ?? undefined,
            status: 'EDITING',
            files: formattedFiles,
            description: values.description,
            ...(values?.consultantExternal?.id
              ? {
                  consultant: values.consultantExternal.id,
                }
              : {
                  consultantExternal: {
                    // birthdate: values.consultantExternal?.birthdate,
                    birthdate: '2002-01-01',
                    docValue: values.consultantExternal?.docValue,
                    mothername: values.consultantExternal?.mothername,
                    phone: values.consultantExternal?.phone,
                    fullname: values.consultantExternal?.fullname,
                    sex: values.consultantExternal?.sex?.value,
                    docType: values.consultantExternal?.docType?.name,
                  },
                }),
          },
        })
        .then(() => {
          AlertMessage({
            type: 'success',
            message: 'Solicitação salva como rascunho',
          });

          History.push('/diagnosis');
        });
      cancelEdit();
    } catch (err) {
      console.log('error', err);
    }
  };

  const handleFormSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (
      (event.nativeEvent as SubmitEvent).submitter === submitButtonRef.current
    ) {
      if (disableField) {
        startEdit();
        return;
      }

      handleSubmit(submitForm, err => {
        AlertMessage({
          type: 'danger',
          message: 'Falha na validação ao salvar solicitação',
        });
        console.log('invalid errors', err);
        console.log('invalid values', getValues());
      })();
    }
  };

  console.log('>>> CanEdit: ', canEdit);
  console.log('>>> CanDownload: ', canDownload);

  return (
    <Container onSubmit={handleFormSubmit}>
      <div
        style={{
          width: '100%',
          height: 'auto',
          backgroundColor: '#fff',
          position: 'sticky',
          zIndex: 10,
          top: 0,
        }}
      >
        <Menu />
        <Subheader>
          <Typography variant="b3_14medium">Visualização</Typography>
        </Subheader>
      </div>
      <BodyContainer>
        <Content>
          <Tabs
            defaultValue={defaultTab}
            value={tab}
            onValueChange={setTab}
            style={{ width: '100%', borderRadius: '0.5rem' }}
          >
            <TabsList>
              <TabsTrigger value="request" onClick={() => handleUrl('request')}>
                Solicitação
              </TabsTrigger>
              <TabsTrigger
                value="history"
                disabled={!disableField || diagnosisData?.pendencies === null}
                onClick={() => handleUrl('history')}
              >
                {`Histórico de devolução (${diagnosisData?.pendencies?.length ?? 0})`}
              </TabsTrigger>
            </TabsList>
            <TabsContent value="request">
              <SectionsContainer>
                <MedicalReportSection />

                <ResponsibleSection />

                <PatientSection />

                <FilesSection
                  canDownload={canDownload}
                  handleLoading={setLoading}
                />
              </SectionsContainer>
            </TabsContent>
            <TabsContent value="history">
              <SectionsContainer>
                <ReturnHistory />
              </SectionsContainer>
            </TabsContent>
          </Tabs>
        </Content>
      </BodyContainer>
      <Footer>
        <FooterContent>
          {!disableField ? (
            <Button
              variant="outlinedDestructive"
              size="md"
              onClick={cancelEdit}
              type="button"
            >
              Cancelar
            </Button>
          ) : (
            <div />
          )}
          <ButtonsContainerSecondary>
            {!disableField && (
              <Button
                variant="outlined"
                type="button"
                onClick={() => {
                  makeReduxActiveMessage().active({
                    active: 'sketchReport',
                    actionOk: () => {
                      sketchDiagnosisReport();
                      closeModal();
                    },
                    actionCancel: () => {
                      closeModal();
                    },
                  });
                }}
                disabled={loading}
              >
                Salvar como rascunho
              </Button>
            )}
            <Button
              variant="primary"
              type="submit"
              disabled={
                !canEdit ||
                loading ||
                tab === 'history' ||
                (diagnosisData &&
                  !(
                    diagnosisData?.status === 'EDITING' ||
                    diagnosisData?.status === 'SUBMITTED'
                  ))
              }
              ref={submitButtonRef}
            >
              {disableField ? 'Editar solicitação' : 'Salvar alterações'}
            </Button>
          </ButtonsContainerSecondary>
        </FooterContent>
      </Footer>
    </Container>
  );
};
