import React, { ComponentType } from 'react';
import { useSelector } from 'react-redux';
import * as datefns from 'date-fns';
import { createIntlCache, createIntl } from 'react-intl';
import {
  iAppointment,
  iAppointmentTypeState,
  iStore,
} from '~/domain/interfaces/models';
import { makeTime } from '~/utils/makeTime';

import { getLocale } from '~/utils/getLocale';
import translations from '~/infra/i18n/locales';
import { iListAppointment } from '../interface';
import { ownProps } from '..';

const cache = createIntlCache();

const intl = createIntl(
  {
    locale: String(getLocale()),
    messages: translations[getLocale()],
  },
  cache,
);

/**
 * Mapping of the appointment list data.
 *
 * @param Component component to connect.
 */
export function ConnectAppointment<P>(
  Component: ComponentType<P & ownProps>,
): React.FC<P> {
  const Render: React.FC<P> = ({ ...rest }) => {
    const { results, loading } = useSelector(
      (store: iStore) => store.appointment,
    );
    const { data: appointmentTypes } = useSelector(
      (store: iStore) => store.appointmentType,
    );

    /* eslint no-param-reassign: "error" */
    Component.defaultProps = {
      data: MapHistoryAppointmentData(results, appointmentTypes.records),
      loading,
      ...(rest as P),
    };

    return <Component {...(rest as P & ownProps)} />;
  };

  return Render;
}

const MapHistoryAppointmentData = (
  appointments: iAppointment['results'],
  appointmentTypes: iAppointmentTypeState['data']['records'],
): iListAppointment[] => {
  const arr: iListAppointment[] = [];

  if (appointments !== undefined)
    appointments?.forEach(item => {
      const date = item.appointment.scheduled;
      const { duration } = item.appointment;

      const typeIs = appointmentTypes.find(
        type => type.id === item.appointment.type,
      );

      const consultantName = item.consultant?.firstName?.length
        ? `${item.consultant?.firstName} ${item.consultant?.lastName}`
        : null;
      const infoName = item.appointment.info?.name?.length
        ? item.appointment.info?.name
        : null;

      const fullName = consultantName ?? infoName ?? '-';

      const statusPlace: Record<string, string> = {
        REMOTE: 'Remoto',
        INPERSON: 'Presencial',
        VISITATION: 'Visitas Domiciliares',
      };

      arr.push({
        fullName: `${item.professional?.firstName} ${item.professional?.lastName}`,
        specialty: item.specialty.name,
        register: `${item.professional?.docProf}-${item.professional?.docProfUF}`,
        timeEnd: makeTime(date, duration).endFormat,
        timeStart: makeTime(date, duration).startFormat,
        typeIs: typeIs?.msgKey
          ? intl.formatMessage({ id: typeIs?.msgKey })
          : '-',
        status: item.appointment.status,
        id: item.appointment.id,
        date: `${datefns.format(
          new Date(item.appointment.scheduled),
          'dd/MM/yyyy',
        )}`,
        srcParticipant: item.consultant?.avatar ? item.consultant?.avatar : '',
        srcProfessional: item.professional?.avatar
          ? item.professional?.avatar
          : '',
        consultantId: item.consultant?.id,
        consultantUserId: item.consultant?.user,
        consultantFullName: fullName,
        professionalId: item.professional?.id,
        professionalStatus: item?.professional?.status,
        title: item?.appointment?.title ?? '',
        service: item?.appointment?.service ?? '',
        modality: statusPlace[item.appointment?.modality] ?? '',
        allowTranscription: item.appointment?.allowTranscription ?? false,
        healthUnit: item.healthUnit?.name ?? '',
        outcome: item.outcome?.msgKey ?? '',
      });
    });

  return arr;
};
