import React from 'react';
import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models';
import { iOnCallRequest } from '~/domain/interfaces/models/OnCallRequest';
import { makeRemoteCreateAppointment } from '~/main/factories/usecases/appointment/CreateAppointmentFactory';
import { makeReduxActiveMessage } from '~/main/factories/usecases/message/Update';
import {
  makeReduxListOnCallRequest,
  makeRemoteListOnCallRequest,
} from '~/main/factories/usecases/onCallRequest/ListOnCallRequestFactory';
import { History } from '~/main/routes';
import { makeRemoteAcceptOnCallSpecialist } from '~/main/factories/usecases/onCallSpecialist/AcceptOnCallSpecialistFactory';
import { closeModal } from '~/utils/closeModal';
import { getProfessionalInfo } from '~/utils/getProfessionalInfo';
import {
  Button,
  Icon,
  IconButton,
  Typography,
} from '@wisecare-tech/design-system-web';
import { AlertMessage } from '../messages/AlertMessage';
import { ContainerPopover, Options } from './styles/Styled';
import { Popover } from '~/presentation/components/UI/popover';
import { makeRemoteCancelOnCallRequest } from '~/main/factories/usecases/onCallRequest/CancelOnCallRequestFactory';
import { makeRemoteJoinOnCallSpecialist } from '~/main/factories/usecases/onCallSpecialist/JoinOnCallSpecialistFactory';
import { makeReduxSetSpecialistStatusOnCall } from '~/main/factories/usecases/onCall/SetSpecialistStatusOnCallFactory';
import { makeRemoteJoinOnCallRequester } from '~/main/factories/usecases/onCallRequester/JoinOnCallRequesterFactory';
import { useParams } from 'react-router-dom';
import { makeRemoteAssignOnCallRequest } from '~/main/factories/usecases/onCallRequest/AssignOnCallRequestFactory';
import { makeRemoteDownloadClinicalDocs } from '~/main/factories/usecases/clinicalDocs/DownloadClinicalDocsFactory';
import { makeRemoteReactivateOnCallRequest } from '~/main/factories/usecases/onCallRequest/ReactivateOnCallRequestFactory';
interface OwnProps {
  info: iOnCallRequest['records'][0] | null;
  selectedRequester?: boolean;
  variant: 'SPECIALIST' | 'REQUESTER';
  handleReset?: () => void;
  setSelectedRequest: (requester: iOnCallRequest['records'][0] | null) => void;
}

const DetailsCardButtons: React.FC<OwnProps> = ({
  info,
  variant,
  handleReset,
  setSelectedRequest,
}) => {
  const { accepted } = useSelector((store: iStore) => store.onCall);

  const { user } = useSelector((store: iStore) => store.auth.info);
  const { orgUnitId } = useSelector((store: iStore) => store.auth.selectUser);

  let { specialty } = useParams<{ specialty: string }>();

  const professional = getProfessionalInfo();
  const isMyRequest =
    info?.requester?.id === professional?.id ||
    info?.specialist?.id === professional?.id;

  const continueInterconsult = () => {
    History.getHistory().push(`/request/interconsult/create`, {
      id: info?.id,
    });
  };

  const acceptInterconsult = () => {
    if (professional && info)
      makeRemoteAcceptOnCallSpecialist()
        .accept({
          onCallId: info?.onCall.id,
          specialistId: professional?.id,
          requestId: info?.id,
        })
        .then(() => {
          makeRemoteJoinOnCallSpecialist()
            .join({
              onCallId: Number(specialty),
              specialistId: professional?.id,
              requestId: Number(info?.id),
            })
            .then(response => {
              History.getHistory().push('/conf', {
                appointmentId: response.appointmentId,
                onCall: {
                  sessionName: response.sessionName,
                  id: Number(specialty),
                  name: Number(specialty).toString(),
                  specialist: professional?.id,
                },
              });
            })
            .catch(() => {
              AlertMessage({
                message: 'Erro ao entrar na conf!',
                type: 'danger',
              });
            });
        })
        .catch(() => {
          AlertMessage({
            message: 'Erro ao aceitar solicitação!',
            type: 'danger',
          });
        });
  };

  const cancelInterconsultRequest = () => {
    if (info && professional)
      makeRemoteCancelOnCallRequest()
        .cancel({
          onCallId: info?.onCall?.id,
          requestId: info?.id,
        })
        .then(() => {
          AlertMessage({
            message: 'Solicitação cancelada com sucesso.',
            type: 'success',
          });
          makeReduxListOnCallRequest().list({
            body: {
              dataControl: {
                limit: 9999,
              },
              filters: {
                requester: professional?.id,
                status: 'WAITING',
                healthUnit: info?.healthUnit.id,
                onCall: info?.onCall.id,
                orgUnit: orgUnitId,
              },
              orderBy: [
                {
                  attribute: 'CREATED',
                  direction: 'DESC',
                },
              ],
            },
          });
          handleReset?.();
          setSelectedRequest(null);
        })
        .catch(() => {
          AlertMessage({
            title: 'Erro ao cancelar solicitação',
            message:
              'Ocorreu um erro ao cancelar a solicitação, tente novamente.',
            type: 'danger',
          });
        });
  };

  const contentPopover = (
    <div
      style={{
        width: '100%',
        backgroundColor: 'white',
        flexDirection: 'column',
        padding: '8px 16px',
      }}
    >
      {info?.permissions?.edit && info?.status !== 'CLOSED' && (
        <div
          style={{
            display: 'flex',
            height: 48,
            alignItems: 'center',
            gap: 8,
            cursor: 'pointer',
          }}
          onClick={() => {
            makeReduxActiveMessage().active({
              active: 'updatedRequestWaiting',
              actionCancel: () => {
                closeModal();
              },
              actionOk: () => {
                closeModal();
              },
              data: {
                requestId: info?.id,
              },
            });
          }}
        >
          <Icon name="edit" />
          <Typography variant="b3_14medium">Editar</Typography>
        </div>
      )}
      {info?.status === 'CLOSED' && variant === 'REQUESTER' && (
        <div
          style={{
            display: 'flex',
            height: 48,
            alignItems: 'center',
            gap: 8,
            cursor: 'pointer',
          }}
          onClick={() => {
            makeReduxActiveMessage().active({
              active: 'twoButtonModal',
              actionCancel: () => {
                closeModal();
              },
              data: {
                variant: 'INFO',
                message:
                  'Sua solicitação foi cancelada pelo profissional e agora você está reenviando para um novo atendimento. Os dados enviados pela primeira vez ficarão o mesmo. Tem certeza que deseja continuar?',
                textSecondaryButton: 'Cancelar',
                textPrimaryButton: 'Sim, quero reenviar',
                title: 'Reenviar solicitação',
              },
              actionOk: () => {
                if (info)
                  makeRemoteReactivateOnCallRequest()
                    .reactivate({
                      onCallId: info?.onCall?.id,
                      requesterId: info?.requester?.id,
                      requestId: info?.id,
                    })
                    .then(() => {
                      AlertMessage({
                        message: 'Solicitação reenviada com sucesso!',
                        type: 'success',
                      });
                      closeModal();
                    })
                    .catch(() => {
                      AlertMessage({
                        message: 'Erro ao reenviar solicitação!',
                        type: 'danger',
                      });
                    });
              },
            });
          }}
        >
          <Icon name="refresh" />
          <Typography variant="b3_14medium">Reenviar solicitação</Typography>
        </div>
      )}
      {info?.currentAppointment.medicalOpinion && (
        <div
          style={{
            display: 'flex',
            height: 48,
            alignItems: 'center',
            gap: 8,
            cursor: 'pointer',
          }}
          onClick={() => {
            if (info)
              makeRemoteDownloadClinicalDocs()
                .download({
                  docId: Number(info.currentAppointment.medicalOpinion),
                })
                .then(response => {
                  const fileURL = URL.createObjectURL(response as Blob);

                  // Abre o arquivo em uma nova guia
                  window.open(fileURL);
                })
                .catch(error => {
                  console.log('>>> Error: ', error);
                  AlertMessage({
                    message: error?.message ?? 'Erro ao baixar arquivo',
                    type: 'danger',
                  });
                });
          }}
        >
          <Icon name="download" />
          <Typography variant="b3_14medium">Baixar parecer médico</Typography>
        </div>
      )}
    </div>
  );

  const renderButton = () => {
    const url = window.location.pathname;

    const renderOptions = {
      WAITING: {
        REQUESTER: (
          <Options>
            <Button
              icon="edit"
              variant="secondary"
              style={{
                width: '161px',
                visibility: info?.permissions?.edit ? 'visible' : 'hidden',
              }}
              onClick={() => {
                History.getHistory().push('/request/create', {
                  id: info?.id,
                  info: {
                    city: {
                      id: info?.healthUnit?.city?.codeFull,
                      name: info?.healthUnit?.city?.name,
                    },
                    healthUnitId: info?.healthUnit?.id,
                    healthUnitName: info?.healthUnit?.name,
                  },
                });
              }}
            >
              Editar
            </Button>

            <Button
              variant="destructive"
              icon="cancel"
              style={{
                width: '100%',
                maxWidth: '161px',
                visibility: info?.permissions?.cancel ? 'visible' : 'hidden',
              }}
              onClick={() => {
                makeReduxActiveMessage().active({
                  active: 'twoButtonModal',
                  actionCancel: () => {
                    closeModal();
                  },
                  data: {
                    variant: 'CANCEL',
                    message:
                      'Você está cancelando esse pedido de interconsulta. Ela será movida para “finalizados”, você poderá reenviar essa solicitação caso precisar. Tem certeza de que deseja continuar?',
                    textSecondaryButton: 'Voltar',
                    textPrimaryButton: 'Sim, quero cancelar',
                    title: 'Cancelar solicitação',
                  },
                  actionOk: () => {
                    cancelInterconsultRequest();
                  },
                });
              }}
            >
              Cancelar
            </Button>
          </Options>
        ),
        SPECIALIST: (
          <Options>
            <Button
              variant="secondary"
              icon="signature"
              style={{
                width: '100%',
                maxWidth: '161px',
              }}
              onClick={() => {
                console.log('>>> tentando criar parecer');
                makeReduxActiveMessage().active({
                  active: 'twoButtonModal',
                  actionCancel: () => {
                    closeModal();
                  },
                  actionOk: () => {
                    if (professional && info)
                      makeRemoteAssignOnCallRequest()
                        .assign({
                          onCallId: Number(specialty),
                          specialistId: professional?.id,
                          requestId: info?.id,
                        })
                        .then(() =>
                          History.getHistory().push(
                            `/duty/${specialty}/opinion/${info?.id}`,
                          ),
                        )
                        .catch(() => {
                          AlertMessage({
                            message: 'Erro ao vincular parecer',
                            type: 'danger',
                          });
                        });
                    closeModal();
                  },
                  data: {
                    variant: 'INFO',
                    message:
                      'Caso comece a escrever o parecer médico, essa solicitação agora ficará sob sua responsabilidade. Tem certeza de que quer continuar?',
                    title: 'Iniciar parecer médico',
                    textSecondaryButton: 'Cancelar',
                    textPrimaryButton: 'Sim, quero continuar',
                  },
                });
              }}
              disabled={!info?.permissions?.accept}
            >
              Parecer
            </Button>
            <Button
              icon="video_call"
              variant="primary"
              style={{ width: '100%', maxWidth: '161px' }}
              disabled={accepted?.state || !info?.permissions?.accept}
              onClick={acceptInterconsult}
            >
              Interconsulta
            </Button>
          </Options>
        ),
      },
      PROGRESS: {
        REQUESTER: (
          <>
            {user?.id === info?.requester?.userId && (
              <Button
                icon="login"
                variant="primary"
                style={{ width: '100%' }}
                onClick={() => {
                  if (!info) return;

                  makeRemoteJoinOnCallRequester()
                    .join({
                      onCallId: info?.onCall?.id,
                      requesterId: info?.requester?.id,
                      requestId: info?.id,
                    })
                    .then(response => {
                      History.getHistory().push('/conf', {
                        appointmentId: response.appointmentId,
                        onCall: {
                          sessionName: response.sessionName,
                          id: info.onCall?.id,
                          name: info.onCall?.name,
                          specialist: info.specialist?.id,
                        },
                      });
                    })
                    .catch(err => {
                      console.log('>>> Error Join Specialist: ', err);
                      makeReduxSetSpecialistStatusOnCall().setSpecialistStatus({
                        available: false,
                      });
                    });
                }}
                disabled={!info?.permissions?.accept}
              >
                Entrar novamente
              </Button>
            )}
          </>
        ),
        SPECIALIST: (
          <Button
            icon="login"
            variant="primary"
            style={{ width: '100%' }}
            onClick={() => {
              if (!info) return;

              makeRemoteJoinOnCallSpecialist()
                .join({
                  onCallId: info?.onCall?.id,
                  specialistId: info?.specialist?.id,
                  requestId: info?.id,
                })
                .then(response => {
                  History.getHistory().push('/conf', {
                    appointmentId: response.appointmentId,
                    onCall: {
                      sessionName: response.sessionName,
                      id: info.onCall?.id,
                      name: info.onCall?.name,
                      specialist: info.specialist?.id,
                    },
                  });
                })
                .catch(err => {
                  console.log('>>> Error Join Specialist: ', err);
                  makeReduxSetSpecialistStatusOnCall().setSpecialistStatus({
                    available: false,
                  });
                });
            }}
            disabled={!info?.permissions?.accept}
          >
            Entrar novamente
          </Button>
        ),
      },
      MONITORING: {
        REQUESTER: (
          <Options>
            <Button
              variant="primary"
              icon="video_call"
              onClick={continueInterconsult}
              style={{ width: '100%' }}
              size="md"
            >
              Criar interconsulta
            </Button>
            <Popover content={contentPopover} originX="left" originY="top">
              <IconButton icon="more_vert" variant="primary" size="md" />
            </Popover>
          </Options>
        ),
        SPECIALIST: (
          <Options>
            <Button
              variant="primary"
              icon="video_call"
              onClick={continueInterconsult}
              style={{ width: '100%' }}
              size="md"
            >
              Criar interconsulta
            </Button>
            <Popover content={contentPopover} originX="left" originY="top">
              <IconButton icon="more_vert" variant="primary" size="md" />
            </Popover>
          </Options>
        ),
      },
      REPORTING: {
        REQUESTER: null,
        SPECIALIST: (
          <Button
            variant="primary"
            icon="edit"
            style={{
              width: '100%',
              visibility: info?.permissions?.sign ? 'visible' : 'hidden',
            }}
            onClick={() => {
              History.getHistory().push(
                `/duty/${specialty}/opinion/${info?.id}`,
                { edit: true },
              );
            }}
          >
            Editar parecer
          </Button>
        ),
      },
      CLOSED: {
        REQUESTER: (
          <Options>
            <Button
              variant="primary"
              icon="visibility"
              size="md"
              style={{ width: '100%' }}
              onClick={() => {
                const url = window.location.pathname;

                History.getHistory().push('/appointment/detail', {
                  appointmentId: info?.currentAppointment?.id,
                  from: url.replaceAll('/', ''),
                });
              }}
            >
              Detalhes
            </Button>
            <Popover content={contentPopover} originX="left" originY="top">
              <IconButton icon="more_vert" variant="primary" size="md" />
            </Popover>
          </Options>
        ),
        SPECIALIST: (
          <Options>
            <Button
              variant="primary"
              icon="visibility"
              style={{ width: '100%' }}
              onClick={() => {
                History.getHistory().push('/appointment/detail', {
                  appointmentId: info?.currentAppointment?.id,
                  from: url.replaceAll('/', ''),
                });
              }}
            >
              Detalhes do atendimento
            </Button>
            <Popover content={contentPopover} originX="left" originY="top">
              <IconButton icon="more_vert" variant="primary" size="md" />
            </Popover>
          </Options>
        ),
      },
    };

    const statusOptions = info?.status ? renderOptions[info.status] : null;

    if (statusOptions && typeof statusOptions === 'object') {
      return (statusOptions as { [key: string]: JSX.Element })[variant] || null;
    }

    return statusOptions || null;
  };

  return <>{renderButton()}</>;
};

export default DetailsCardButtons;
