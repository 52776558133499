import styled from 'styled-components';

type ContainerProps = {
  hasButtonBack?: boolean;
};

export const Container = styled.div<ContainerProps>`
  background: #fcfcfc;
  width: 100%;
  color: #656a6e;
  border-bottom: 1px solid rgb(122, 122, 122);
  display: flex;
  justify-content: space-between;

  padding: 0.75rem 4.5rem;

  #center {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 50px;
  }

  #left {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex: 1;
  }

  #right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 1;
  }
`;

export const Title = styled.div`
  font-weight: 700;
  /* margin-left: 8px; */
  padding-right: 8px;
`;
