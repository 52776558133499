import styled, { css } from 'styled-components';

export const ContainerModal = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  background: rgba(0, 0, 0, 0.7);
  z-index: 11;
`;

export const Container = styled.div`
  width: 100%;
  max-width: 530px;
  border-radius: 16px;
  border: 1px solid #dedede;
  background-color: #fff;
`;

export const Content = styled.div`
  padding: 16px 24px;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #dedede;
`;

export const Profession = styled.div`
  display: flex;
  padding: 16px 24px;
  flex-direction: column;
`;

export const Data = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const Header = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

export const Icon = styled.div`
  margin-bottom: 8px;
`;

export const Title = styled.h1`
  font-size: 18px;
  font-weight: 600;
  color: #222529;
  margin-bottom: 8px;
`;

export const Information = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Info = styled.span`
  font-size: 16px;
  font-weight: 400;
  color: #656a6e;
`;

export const Footer = styled.div`
  border-top: 1px solid #dedede;
  padding: 24px;
  display: flex;
  justify-content: flex-end;
`;

interface PriorityProps {
  priority: 'EMERGENCY' | 'NORMAL' | 'LOW';
}

export const priorityColorStyle = (
  priority: 'EMERGENCY' | 'NORMAL' | 'LOW',
) => {
  switch (priority) {
    case 'EMERGENCY':
      return css`
        svg path {
          fill: #dd0404;
        }
      `;

    case 'NORMAL':
      return css`
        svg path {
          fill: #fe632f;
        }
      `;

    case 'LOW':
      return css`
        svg path {
          fill: #458108;
        }
      `;

    default:
      return css`
        svg path {
          fill: #458108;
        }
      `;
  }
};

export const Priority = styled.div<PriorityProps>`
  display: flex;
  align-items: center;
  gap: 8px;

  ${({ priority }) => priorityColorStyle(priority)}
`;
